@import '@styles/Variables';

$transitionSpeed: 0.5s;

.wrapper {
    position: relative;
    width: 100%;
    padding: 10px;
    // border: 2px dashed $grey3;
    border: 1px solid $grey2;
    border-radius: 10px;
    // background-color: white;
    background-color: $grey1;
    transition: all 0.3s;

    &.dragOver {
        // border: 2px dashed $blue3;
        border: 1px solid $blue3;
        background-color: $blue7;
    }

    .focusButton {
        all: unset;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .reverseColumn {
        // used to display toolbar above input but load it after (to prevent button '.active' class bug)
        flex-direction: column-reverse;

        .toolbarWrapper {
            height: 0;
            opacity: 0;
            transition: all $transitionSpeed;

            .toolbar {
                width: 100%;
                display: none;
                z-index: 2;

                > div {
                    display: flex;
                }

                .button {
                    pointer-events: none;
                    border: 1px solid $grey2;
                    border-radius: 5px;
                    background-color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    pointer-events: auto;
                    height: 22px;
                    width: 22px;
                    margin: 0 2px 2px 0;
                    padding: 3px;

                    @include for-tablet-landscape-up {
                        height: 32px;
                        width: 32px;
                        margin: 0 5px 5px 0;
                        padding: 5px;
                    }

                    &:hover {
                        background-color: $grey1;
                    }

                    > svg {
                        width: 18px;
                    }

                    &.active {
                        background-color: $grey2;
                    }
                }

                .linkInput {
                    width: 100%;
                    padding: 6px 8px;
                    outline: none;
                    border: 1px solid $grey2;
                    background-color: white;
                    border-radius: 5px;
                    margin-bottom: 5px;
                }
            }

            &.visible {
                height: 40px;
                opacity: 1;

                .toolbar {
                    display: flex;
                    .button {
                        pointer-events: auto;
                    }
                }
            }
        }

        .inputWrapper {
            :global(.DraftEditor-root) {
                padding: 0px 8px;
                width: 100%;
                // max-width: 520px;
            }

            :global(.DraftEditor-editorContainer) {
                background-color: white;
                // background-color: $grey1;
                border: 1px solid $grey2;
                border-radius: 10px;
                padding: 10px 12px !important;
            }

            :global(.public-DraftEditorPlaceholder-root) {
                padding: 10px 12px;
                color: $grey4;
                position: absolute;
                pointer-events: none;
            }

            .mediaButton {
                all: unset;
                cursor: pointer;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background-color: white;
                // background-color: $grey1;
                border: 1px solid $grey2;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 5px;
                transition: all 0.5s;

                svg {
                    width: 20px;
                    height: 20px;
                    color: $grey6;
                    transition: all 0.5s;
                }

                label {
                    cursor: pointer;
                    width: inherit;
                    height: inherit;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &:hover {
                    // background-color: $blue7;
                    // border: 1px solid rgba($blue5, 0.5);
                    background-color: $blue7;
                    border: 1px solid $grey3;
                    svg {
                        // color: $blue5; // $grey7;
                        color: $grey7;
                    }
                }

                .stop {
                    width: 15px;
                    height: 15px;
                    border-radius: 2px;
                    background-color: $likeRed;
                }
            }

            .recordingTime {
                position: absolute;
                left: -105px;
                height: 35px;
                background-color: white;
            }
        }
    }

    .footer {
        position: relative;
        width: 100%;
        height: 0;
        opacity: 0;
        transition: all $transitionSpeed;
        align-items: flex-end;

        &.visible {
            height: 22px;
            opacity: 1;
        }

        p {
            font-size: 14px;
            line-height: 14px;
            color: $grey4;
            margin: 0;

            &.error {
                color: $red;
            }
        }
    }

    .image {
        position: relative;

        &:not(:last-child) {
            margin-right: 10px;
        }

        > img {
            width: 100px;
            height: 100px;
            border-radius: 5px;
            object-fit: cover;
            object-position: center;
        }
    }

    .audio {
        position: relative;
        width: 100%;
        height: 100px;
        max-width: 600px;

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    // types
    &.poll-answer {
        background-color: white;

        &.dragOver {
            background-color: $blue7;
        }

        // .inputWrapper {
        //     :global(.DraftEditor-editorContainer) {
        //         background-color: $grey1;
        //     }
        // }
    }
}
