@import '@styles/Variables';

$transitionSpeed: 0.5s;

.wrapper {
    padding: 40px 0;
    width: 100vw;
    // width: calc(100vw - 30px);

    @include for-tablet-landscape-up {
        padding: 40px;
        width: auto;
    }

    &.event {
        // allow date-time-picker to overflow modal
        overflow: unset;
    }

    .postCard {
        width: 100%;
        background-color: white;
        box-shadow: 0 7px 10px 0 rgba(10, 8, 72, 0.04);
        border: 1px solid $grey2;
        position: relative;
        opacity: 1;
        padding: 20px;
        border-radius: 0;
        margin-bottom: 20px;

        @include for-tablet-landscape-up {
            width: 780px;
            border-radius: 10px;
        }

        .header {
            font-size: 15px;
            line-height: 15px;
            margin-bottom: 10px;

            .addSpacesButton {
                all: unset;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
                height: 30px;
                padding: 0 10px;
                border: 1px solid $grey4;
                border-radius: 15px;
                color: $grey6;
                cursor: pointer;
                transition: all $transitionSpeed;

                &:hover {
                    color: $blue3;
                    border: 1px solid $blue3;
                    background-color: $blue7;
                }
            }
        }

        .content {
            .title {
                margin-bottom: 5px;

                >input {
                    all: unset;
                    width: 100%;
                    font-size: 24px;
                }

                >button {
                    pointer-events: none;
                    opacity: 0;
                    transition: all 0.5s;
                }

                &:hover {
                    >button {
                        pointer-events: all;
                        opacity: 1;
                    }
                }
            }

            .topic {
                margin-bottom: 10px;

                .imageWrapper {
                    background-color: $grey2;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    margin-right: 10px;
                    flex-shrink: 0;
                    position: relative;

                    >img {
                        width: inherit;
                        height: inherit;
                        border-radius: inherit;
                        object-fit: cover;
                        position: absolute;
                        background-color: white;
                        box-shadow: 0 0 10px 0 rgba(10, 8, 72, 0.1);
                    }

                    >svg {
                        color: $grey5;
                        width: 30px;
                        height: 30px;
                    }

                    >label {
                        position: absolute;
                        width: 60px;
                        height: 60px;
                        cursor: pointer;
                        border-radius: 50%;
                    }
                }

                .text {
                    position: relative;
                    width: 100%;

                    >input {
                        all: unset;
                        width: 100%;
                        font-size: 24px;
                    }

                    .topicOptions {
                        position: absolute;
                        top: 40px;
                        padding: 10px;
                        background-color: white;
                        box-shadow: 0 0 10px 0 rgba(10, 8, 72, 0.06);
                        border-radius: 5px;
                        z-index: 5;

                        .option {
                            all: unset;
                            cursor: pointer;
                            display: flex;
                            align-items: center;

                            &:not(:last-child) {
                                margin-bottom: 10px;
                            }

                            img {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                margin-right: 5px;
                                object-fit: cover;
                                background-color: white;
                                box-shadow: 0 0 5px 0 rgba(10, 8, 72, 0.1);
                            }
                        }
                    }
                }
            }

            .text {
                :global(.DraftEditor-editorContainer) {
                    max-height: 400px;
                    overflow: auto;
                }
            }

            ::placeholder {
                color: $grey4;
                opacity: 1;
            }

            .blockWrapper {
                position: relative;
                margin-top: 15px;
                padding: 10px;
                border: 1px solid $grey2;
                border-radius: 10px;
                background-color: $grey1;
            }

            .dropBlockWrapper {
                position: relative;
                margin-top: 15px;
                padding: 10px;
                border: 2px dashed $grey3;
                border-radius: 10px;
                background-color: $grey1;
                transition: all 0.3s;

                &.dragOver {
                    border: 2px dashed $blue3;
                    background-color: $blue7;
                }
            }

            .images {
                padding: 10px;
                margin-top: 5px;

                .imageWrapper {
                    position: relative;

                    &:not(:last-child) {
                        margin-right: 10px;
                    }

                    .imageButton {
                        all: unset;
                        cursor: pointer;

                        >img {
                            object-fit: cover;
                            margin-bottom: 10px;
                            box-shadow: 0 0 10px 0 rgba(10, 8, 72, 0.06);
                        }
                    }

                    &.large {
                        .imageButton>img {
                            max-width: 100%;
                            max-height: 450px;
                        }

                        >p {
                            width: 100%;
                        }
                    }

                    &.medium {
                        .imageButton>img {
                            width: 300px;
                            height: 300px;
                        }

                        >p {
                            width: 100%;
                        }
                    }

                    &.small {
                        .imageButton>img {
                            width: 225px;
                            height: 225px;
                        }

                        >p {
                            width: 100%;
                        }
                    }

                    .itemFooter {
                        height: 20px;
                        margin: 7px 10px 5px 0;

                        >button {
                            all: unset;
                            cursor: pointer;
                            width: 20px;
                            height: 20px;
                            margin: 0 10px;

                            >svg {
                                opacity: 0.15;
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }

            .pollAnswers {
                margin-top: 10px;

                >div {
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
            }

            .icon {
                width: 22px;
                height: 22px;
                color: $grey4;
                position: absolute;
                left: 15px;
            }

            .cardContainer {
                position: relative;
                margin-top: 15px;
                padding: 10px;
                border: 1px solid $grey2;
                border-radius: 10px;
                background-color: $grey1;

                .cardFlip {
                    position: absolute;
                    top: 220px;
                    right: 40px;
                    z-index: 5;

                    >button {
                        all: unset;
                        cursor: pointer;

                        >svg {
                            width: 30px;
                            height: 30px;
                            color: $grey4;
                        }
                    }
                }

                .cardWrapper {
                    width: 500px;
                    transition: all 0.3s;
                    height: 350px;
                    margin: 40px;

                    .card {
                        width: 250px;
                        height: 350px;
                        box-shadow: 0 0 30px 0 rgba(10, 8, 72, 0.2);
                        border-radius: 15px;
                        background-color: white;
                        transition: transform ease-in-out 0.5s;

                        &.rotating {
                            transform: rotateY(90deg);
                        }

                        .cardContent {
                            position: relative;
                            width: inherit;
                            height: inherit;
                            border-radius: inherit;

                            >img {
                                position: absolute;
                                border-radius: inherit;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }

            .game {
                .gameInfo {
                    margin-top: 10px;
                    color: $grey5;

                    >div>svg {
                        width: 25px;
                        height: 25px;
                        margin-right: 5px;
                    }
                }

                .beads {
                    padding: 15px;
                    background-color: $grey1;
                    border: 1px solid $grey2;
                    border-radius: 10px;
                    margin-top: 10px;

                    .beadDivider {
                        width: 35px;
                        height: 300px;
                        opacity: 1;
                        animation-name: fadeInOpacity;
                        animation-timing-function: ease-in;
                        animation-duration: 2s;

                        >svg {
                            transform: rotate(90deg);
                            height: 35px;
                            color: $bluegrey2;
                        }
                    }

                    .newBeadButton {
                        all: unset;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 300px;
                        height: 300px;
                        flex-shrink: 0;
                        border-radius: 15px;
                        background-color: white;
                        box-shadow: 0 0 7px 0 rgba(10, 8, 72, 0.1);
                        transition: all 0.3s;

                        &:hover {
                            background-color: rgb(245, 251, 255);
                        }

                        >svg {
                            width: 40px;
                            height: 40px;
                            color: $grey6;
                            margin-bottom: 10px;
                        }

                        >p {
                            color: $grey6;
                            width: 150px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .contentOptions {
        margin-bottom: 20px;
        position: relative;

        >div,
        >input,
        >button {
            margin-bottom: 10px;
        }

        h2 {
            margin: 0;
        }

        .errors {
            margin-bottom: 20px;

            >p {
                font-size: 14px;
                color: $red;
            }
        }
    }

    .contentButton {
        all: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border: 1px solid $grey3;
        border-radius: 50%;
        background-color: white;
        cursor: pointer;
        transition: all $transitionSpeed;

        &:not(:last-child) {
            margin-right: 10px;
        }

        svg {
            color: $grey7;
            width: 25px;
            height: 25px;
            transition: all $transitionSpeed;
        }

        &:hover,
        &.selected {
            border: 1px solid $blue3;
            background-color: $blue7;

            svg {
                color: $blue3;
            }
        }
    }

    .errors {
        margin-bottom: 30px;
        color: $red;
    }

    .fileUploadInput {
        label {
            height: 36px;
            border-radius: 18px;
            padding: 0px 14px;
            font-size: 14px;
            border: none;
            outline: none;
            display: flex;
            justify-content: center;
            align-items: center;
            transition-property: background-color, filter, color;
            transition-duration: 0.3s;
            flex-shrink: 0;
            background-color: $aqua;
            color: white;

            &:hover {
                &:not(.disabled) {
                    cursor: pointer;
                    filter: brightness(110%);

                    &.grey {
                        filter: brightness(96%);
                    }
                }
            }
        }
    }
}
