@import '@styles/Variables';

.wrapper {
    position: relative;
    width: 100%;
    height: $coverImageHeight;
}
.placeholder {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(141deg, #9fb8ad 0%, #1fc8db 51%, #2cb5e8 75%);
    // linear-gradient(180deg, #0b7285 0%, #0ca678 75%); //linear-gradient(#0b7285, #0ca678);
}
.uploadButton {
    all: unset;
    position: absolute;
    top: 0;
    width: 100%;
    height: $coverImageHeight;
    cursor: pointer;
    background-color: rgba(black, 0.4);
    color: white;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.3s;
    &:hover {
        opacity: 1;
    }
}
