@import '@styles/Variables';

.wrapper {
    width: 100%;
    height: 100%;
    background-color: $grey2;
    color: $grey5;
    border-radius: inherit;

    svg {
        width: 100%;
        height: 100%;
    }
}
