@import '@styles/Variables';

.wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    p {
        margin: 0 0 0 5px !important;
        font-size: 14px;
        white-space: nowrap;
    }
}
