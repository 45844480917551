$speed: 0.7s;

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.wrapper {
    overflow: hidden;

    > svg {
        animation: rotate $speed linear infinite;
        transform-origin: center;
    }
}
