@import '@styles/Variables';

.wrapper {
    width: 100%;
    min-height: 100vh;
    padding-top: 60px;
    background-color: #002d50;
    height: 100%;
    z-index: 1;

    .background {
        position: absolute;
        background-color: #002d50;
        width: 100%;
        z-index: 2;

        .sky {
            width: 100%;
            height: 400px;
            background-color: $bluegrey1;
        }

        .earthImage {
            background-color: $bluegrey1;
            margin-top: -5px;

            > img {
                width: 100%;
                margin-bottom: -10px;
            }
        }
    }

    .content {
        z-index: 3;
        width: 100%;

        .logo {
            width: 300px;
            margin-top: 30px;

            @include for-tablet-landscape-up {
                width: 350px;
            }
        }

        > h1 {
            color: $grey7;
            font-size: 40px;
            line-height: 40px;
            font-weight: 400;
            margin-bottom: 5px;
            letter-spacing: 4px;

            .colored {
                background: $aquaGradient;
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        > h2 {
            font-size: 20px;
            font-weight: 400;
            color: $grey6;
            text-align: center;
            margin-bottom: 30px;
        }

        .highlights {
            color: white;
            margin-top: 140px;
            display: flex;
            flex-direction: column;
            align-items: center;

            > button {
                margin-bottom: 20px;
                margin-right: 0;
            }

            @include for-tablet-landscape-up {
                flex-direction: row;
                justify-content: space-between;

                > button {
                    margin-bottom: 20px;
                    &:not(:last-child) {
                        margin-right: 30px;
                    }
                }
            }
        }

        .emptyHighlights {
            height: 205px;
        }
    }
}
