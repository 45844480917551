@import '@styles/Variables';

$speed: 1.8s;

@keyframes pulse1 {
    0% {
        transform: scale(0.4);
    }
    25% {
        transform: scale(0.5);
    }
    50% {
        transform: scale(0.4);
    }
    100% {
        transform: scale(0.4);
    }
}

@keyframes pulse2 {
    0% {
        transform: scale(0.4);
        opacity: 0.8;
    }
    50% {
        transform: scale(1);
        opacity: 0;
    }
    100% {
        transform: scale(0.4);
        opacity: 0;
    }
}

.wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon1,
    .icon2 {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $likeRed;
    }

    .icon1 {
        animation: pulse1 $speed ease-in infinite;
    }

    .icon2 {
        animation: pulse2 $speed ease-in infinite;
    }
}
