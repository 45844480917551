@import '@styles/Variables';

.wrapper {
    position: relative;
    height: calc(100vh - 60px);
    width: 100%;
    margin-top: 60px;
    padding: 20px 0;

    @include for-tablet-portrait-up {
        padding: 20px;
    }

    .loading {
        position: absolute;
        height: calc(100% - 40px);
        width: 100%;
        background-color: $grey1;
        opacity: 1;
        z-index: 10;
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        @include for-tablet-landscape-up {
            flex-direction: row-reverse;
        }

        .info {
            padding: 5px 5px 130px 5px;

            @include for-tablet-landscape-up {
                width: 100%;
                max-width: 780px;
                margin-right: 20px;
                overflow-y: scroll;
            }

            .targetInput {
                position: relative;

                .targetOptions {
                    position: absolute;
                    top: 50px;
                    right: 0;
                    background-color: white;
                    padding: 5px;
                    border-radius: 5px;
                    box-shadow: 0 10px 10px 0 rgba(10, 8, 72, 0.06);
                    z-index: 5;

                    .targetOption {
                        padding: 5px;
                        border-radius: 5px;
                        cursor: pointer;

                        &:hover {
                            background-color: $blue8;
                        }
                    }
                }
            }

            .targetSearchButton {
                all: unset;
                cursor: pointer;
                width: 30px;
                height: 30px;
                margin-left: 15px;

                > svg {
                    width: inherit;
                    height: inherit;
                    color: $grey3;
                    transition: color 0.5s;

                    &:hover {
                        color: $grey5;
                    }
                }
            }

            .targetSearchOptions {
                > div {
                    margin-bottom: 20px;
                    padding: 5px;
                    border: 2px solid $grey1;
                    border-radius: 10px;
                    transition: all 0.5s;

                    &:hover {
                        border: 2px solid $blue1;
                        background-color: $blue6;
                    }

                    > button {
                        margin-top: 10px;
                    }
                }
            }

            .targetNotFound {
                width: 100%;
                padding: 20px;
                background-color: white;
                border-radius: 10px;
            }

            .link {
                margin: 10px 0;
                padding: 15px;
                background-color: white;
                border-radius: 10px;

                .header {
                    margin-bottom: 10px;

                    > p {
                        margin-left: 7px;
                        font-size: 14px;
                        color: $grey4;
                    }

                    > button {
                        all: unset;
                        cursor: pointer;
                        margin-left: 15px;
                        width: 16px;
                        height: 16px;

                        svg {
                            width: inherit;
                            height: inherit;
                            color: $grey5;
                        }
                    }
                }

                > button {
                    all: unset;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    color: $grey4;
                    transition: all 0.5s;

                    svg {
                        width: 16px;
                        height: 16px;
                        margin-right: 3px;
                    }

                    &:hover {
                        color: $grey6;
                    }

                    &.blue > svg {
                        color: $blue1;
                    }
                }
            }
        }

        .visualisation {
            padding: 5px 5px 100px 5px;
            width: 100%;

            @include for-tablet-landscape-up {
                height: 100%;
            }

            .canvas {
                width: 100%;
                height: 100%;
            }
        }
    }
}
