@import '@styles/Variables';

.wrapper {
    all: unset;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;

    .leftText {
        margin-right: 10px;
    }

    .rightText {
        margin-left: 10px;
    }

    .toggle {
        width: 45px;
        height: 26px;
        border-radius: 13px;
        padding: 3px;
        background-color: $grey4;
        position: relative;
        transition: background-color 0.5s;

        &.blue {
            background-color: $blue5;
        }

        .toggleButton {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $white;
            position: absolute;
            left: calc(100% - 23px);
            transition: left 0.5s;
        }

        p {
            position: absolute;
            top: 9px;
            left: calc(0% + 6px);
            font-size: 8px;
            line-height: 8px;
            color: white;
        }

        &.left {
            .toggleButton {
                left: calc(0% + 3px);
            }

            p {
                left: calc(100% - 20px);
            }
        }
    }
}
