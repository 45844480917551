@import '@styles/Variables';

.wrapper {
    position: relative;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid $grey2;
    background-color: white;
    min-height: 60px;
    word-break: break-word;

    &.done {
        background-color: $blue7;
    }

    .index {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 5px;
        margin-right: 10px;
        flex-shrink: 0;
        z-index: 1;

        > p {
            color: white;
        }
    }

    .blocks {
        width: 100%;
        min-width: 0;
        margin-top: 10px;

        > div {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        .image {
            position: relative;

            &:not(:last-child) {
                margin-right: 10px;
            }

            > img {
                width: 100px;
                height: 100px;
                border-radius: 5px;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    .check {
        width: 20px;
        height: 20px;
        color: $blue5;
        margin-left: 10px;
    }

    .stats {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        border-radius: 10px;
        border: 1px solid $grey2;
        padding: 7px;
        background-color: $grey1;
        transition: background-color 0.3s;

        &:hover {
            background-color: $blue8;
        }

        .statButton {
            all: unset;
            position: absolute;
            cursor: pointer;
            width: 100%;
            height: 100%;
            z-index: 5;
        }

        > p {
            font-weight: 800;
        }
    }

    .toggleDoneButton {
        all: unset;
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-left: 5px;
        color: $grey3;
        transition: color 0.5s;

        &:hover {
            color: $grey4;
        }
    }
}
