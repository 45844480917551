@import '@styles/Variables';

.wrapper {
    padding: 10px;
    border: 1px solid $grey2;
    border-radius: 10px;
    background-color: $grey1;
    margin-bottom: 10px;

    .eventTimes {
        text-align: center;
        svg {
            width: 20px;
            height: 20px;
            color: $grey5;
        }

        p {
            margin-left: 10px;
        }
    }
}
