@import '@styles/App.scss';

$defaultColor: $grey7;
$highlightColor: $blue5;

.wrapper {
    display: none;
    @include for-tablet-landscape-up {
        display: flex;
    }
    overflow: hidden;
    background-color: white;
    z-index: 2;

    .tab {
        margin: 0 10px;
        color: $defaultColor;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            font-size: 18px;
            line-height: 18px;
        }

        svg {
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }

        .underline {
            height: 2.5px;
            width: 100%;
            margin-top: 5px;
        }

        &:hover {
            .underline {
                background-color: $defaultColor;
            }
        }

        &.selected {
            color: $highlightColor;
            .underline {
                background-color: $highlightColor;
            }
        }
    }
}
