@import '@styles/Variables';

.wrapper {
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
    background-color: white;
    padding: 3px;
    box-shadow: 0 0 10px 0 rgba(10, 8, 72, 0.08);
    position: relative;

    .flagImage {
        position: absolute;
        top: 3px;
        left: 3px;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        flex-shrink: 0;
    }

    .background {
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border-radius: inherit;
        background-color: white;
    }

    > button {
        all: unset;
        position: absolute;
        top: 3px;
        left: 3px;
        border-radius: inherit;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        cursor: pointer;
        background-color: rgba(black, 0.4);
        color: white;
        font-size: 16px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 0.3s;
        &:hover {
            opacity: 1;
        }
    }
}
