@import '@styles/Variables';

.dropDownMenu {
    display: flex;
    flex-direction: column;
    position: relative;
    &.horizontal {
        flex-direction: row;
        align-items: center;
        > .title {
            margin-bottom: 0;
            margin-right: 10px;
        }
        > .dropDown {
            > .selectedOption {
                background-color: rgba(0, 0, 0, 0.1);
            }
            > .options {
                top: 36px;
            }
        }
    }
}
.title {
    font-size: 12px;
    margin-bottom: 5px;
}
.dropDown {
    display: flex;

    &.dark {
        .selectedOption {
            background-color: #58585d;
            color: white;
        }
    }
}
.selectedOption {
    position: relative;
    font-size: 14px;
    padding: 0 28px 0 10px;
    height: 30px;
    background-color: white; //rgba(0,0,0,0.4); // #e1e3e9;//#e5e5e5;//white;//rgba(0,0,0,0);
    box-shadow: 0 1px 10px 0 rgba(10, 8, 72, 0.04);
    //border: 1px solid rgba(0,0,0,0.1);
    //color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    align-self: flex-start;
    flex-shrink: 0;
    &:hover {
        cursor: pointer;
    }
}
.options {
    display: none;
    &.visible {
        display: flex;
    }
    background-color: white; // rgb(248, 248, 248);
    box-shadow: 0 1px 10px 0 rgba(10, 8, 72, 0.15);
    //border: 1px solid rgba(0,0,0,0.1);
    padding: 10px;
    border-radius: 5px;
    flex-shrink: 0;
    flex-direction: column;
    position: absolute;
    top: 58px;
    z-index: 5;
    &.horizontal {
        top: 35px;
    }
}
.option {
    font-size: 14px;
    line-height: 1;
    white-space: nowrap;
    margin-bottom: 10px;
    &:hover {
        cursor: pointer;
    }
    &:last-child {
        margin-bottom: 0;
    }
    // flex-shrink: 0;
    // flex-wrap: nowrap;
    // padding: 0 0 0 10px;
}
.icon {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 5px;
    opacity: 0.43;
    margin-top: -3px;
    //filter: invert(100%);
}
// .select {
//     //margin: 5px 0 0 0;
//     height: 30px;
//     font-size: 14px;
//     background-color: rgba(0,0,0,0.1);
//     border-radius: 5px;
//     padding: 0 5px;
//     //background-color: transparent;
//     position: relative;
//     border: none;
//     outline: none;
// }
