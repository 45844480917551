@import '@styles/Variables';

.wrapper {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    padding-top: 5px;

    .noResults {
    }
}
