@import '@styles/Variables';

$borderRadius: 15px;

.wrapper {
    width: 300px;
    height: 300px;
    outline: 1px solid rgba(black, 0.1);
    box-shadow: 0 0 10px 0 rgba(black, 0.1);
    border-radius: $borderRadius;
    background-color: white;
    margin-right: 10px;
    position: relative;
    transition: all 0.5s;

    .button {
        all: unset;
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .agent {
        width: 100%;
        height: 100%;

        .coverImage {
            width: 100%;
            height: 90px;
            background-size: cover;
            background-position: center;
            border-top-left-radius: $borderRadius;
            border-top-right-radius: $borderRadius;
            flex-shrink: 0;
        }

        .flagImage {
            margin: -50px 0 5px 0;
        }

        > h1 {
            font-size: 20px;
            line-height: 22px;
            margin-bottom: 3px;
            text-align: center;
        }

        > h2 {
            font-size: 16px;
            color: $grey4;
            margin-bottom: 5px;
        }

        > div > p {
            font-size: 15px;
            text-align: center;
        }

        .stat {
            color: $grey4;

            &:not(:last-child) {
                margin-right: 15px;
            }

            svg {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }
    }

    .item {
        padding: 10px;
        width: 100%;
        height: 100%;

        .header {
            margin-bottom: 5px;

            .id {
                cursor: pointer;
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 14px;
            }
        }

        .center {
            height: 100%;
            width: 100%;
            overflow: hidden;
            font-size: 14px;
        }
    }
}
