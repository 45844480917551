@import '@styles/Variables';

.wrapper {
    width: 100%;
    max-width: 800px;
    margin-bottom: 130px;

    @include for-tablet-portrait-up {
        padding: 0 15px;
    }

    .placeholder {
        width: 100%;
        padding: 40px;
        background-color: white;
        border-radius: 10px;

        > p {
            margin-right: 20px;
        }
    }
}
