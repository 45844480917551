@import '@styles/Variables';

.wrapper {
    width: 100%;
    max-width: 930px;
    padding: 0 15px;
    margin-bottom: 135px;

    .content {
        width: 100%;
        background-color: white;
        border-radius: 10px;
        padding: 40px;
        z-index: 1;

        > div,
        > button {
            margin-bottom: 15px;
        }

        > div {
            > h1 {
                font-size: 16px;
                font-weight: 600;
                margin: 0 5px 0 0;
            }

            > p {
                margin: 0 10px 0 0;
            }
        }
    }
}
