//-------------------- Dark Mode --------------------//
.dark-mode {
    background-color: rgb(45, 45, 48);
    transition-property: background-color;
    transition-duration: 2s;

    .navbar {
        background-color: rgb(30, 30, 33);
        transition-property: background-color;
        transition-duration: 2s;
    }

    .input-wrapper {
        background-color: rgb(70, 70, 75);
        transition-property: background-color;
        transition-duration: 2s;

        &:hover {
            box-shadow: 0 0 5px 6px $purple-o;
        }
    }

    .post,
    .comment,
    .comments {
        background-color: rgb(70, 70, 75);
        transition-property: background-color;
        transition-duration: 2s;
    }

    .pinned-post,
    .dropdown-content {
        background-color: rgb(90, 90, 95);
        transition-property: background-color;
        transition-duration: 2s;
    }

    .create-post-modal {
        background-color: rgb(70, 70, 75);
        transition-property: background-color;
        transition-duration: 2s;
    }

    .homepage,
    .empty-page,
    .post-title,
    .page-title,
    .post-description,
    .comment-text,
    .dropdown-item {
        color: rgb(200, 200, 200);
        transition-property: color;
        transition-duration: 2s;
    }

    .button {
        background-color: $purple;
        transition-property: background-color;
        transition-duration: 2s;
    }
}
