@import '@styles/Variables';

.wrapper {
    width: 100%;
    z-index: $toybarZIndex;
    position: fixed;
    bottom: 0;
    background-color: rgba($grey1, 0.9);
    box-shadow: 0 0px 20px 0 rgba(10, 8, 72, 0.14);
    transition: all 0.3s;

    .collapseButton {
        all: unset;
        cursor: pointer;
        position: absolute;
        top: 0;
        opacity: 0;
        pointer-events: none;
        left: calc(50% - 20px);
        width: 40px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($grey1, 0.9);
        color: $grey5;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        transition-property: top, opacity, color;
        transition-duration: 0.3s;
        border-top: 1px solid $grey3;
        border-left: 1px solid $grey3;
        border-right: 1px solid $grey3;

        > svg {
            width: 18px;
            height: 18px;
        }

        &:hover {
            color: $blue5;
        }
    }

    &:hover {
        .collapseButton {
            top: -24px;
            opacity: 1;
            pointer-events: all;
        }
    }

    &.collapsed {
        bottom: -120px;
        .collapseButton {
            top: -24px;
            width: 100%;
            left: 0;
            opacity: 1;
            pointer-events: all;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .left {
        background-color: $grey1;
        padding: 10px;

        &.mobile {
            width: 100%;
            justify-content: center;

            .newButtons {
                left: calc(50% - 60px);
            }
        }

        .newButtons {
            position: absolute;
            left: 0;
            bottom: -230px;
            background-color: $grey1;
            border-radius: 10px;
            box-shadow: 0 0px 15px 0 rgba(black, 0.05);
            transition: all 0.4s;
            pointer-events: none;
            padding: 10px;
            z-index: -5;
            opacity: 0;

            > button {
                margin-bottom: 10px;

                > svg {
                    width: 36px;
                    height: 36px;
                }
            }

            &.visible {
                pointer-events: all;
                bottom: 102px;
                opacity: 1;
            }
        }

        .rowCounter {
            width: 100px;
            height: 100px;
            margin-left: 10px;

            > button {
                all: unset;
                cursor: pointer;
                width: 22px;
                height: 22px;
                color: $grey4;

                svg {
                    width: inherit;
                    height: inherit;
                }
            }

            > button:disabled {
                color: $grey2;
                cursor: auto;
            }

            .rowInfo {
                height: 100%;
                width: 100px;

                button {
                    all: unset;
                    position: relative;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    width: 100%;

                    img {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                    }

                    p {
                        position: absolute;
                        font-size: 14px;
                        width: 100%;
                        text-align: center;
                        background-color: rgba(white, 0.8);

                        &.large {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .right {
        background-color: white;
        padding: 10px;
    }

    .button {
        all: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        border: 1px solid $grey5;
        border-radius: 10px;
        background-color: $grey1;
        color: $grey6;
        transition: all 0.3s;
        cursor: pointer;
        flex-shrink: 0;
        font-size: 14px;

        &:hover {
            background-color: $blue7;
            border: 1px solid $blue5;
            color: $blue5;
        }

        > svg {
            width: 40px;
            height: 40px;
            pointer-events: none;
        }

        > p {
            margin-top: 5px;
        }

        &.hover {
            opacity: 1;
            background-color: $blue8;
            border: 1px solid $blue5;
            color: $blue5;
        }
    }

    .scroll {
        padding: 2px;
        width: 100%;
    }

    .toybox {
        height: 100px;
        width: 100%;
        transition: opacity 0.3s;

        .inbox {
            opacity: 0.3;
            transition: all 0.3s;
            cursor: default;

            &:hover {
                border: 1px solid $grey5;
                background-color: $grey1;
                color: $grey6;
            }

            &.highlighted {
                opacity: 1;
                background-color: $blue7;
                border: 1px solid $blue5;
                color: $blue5;
            }
        }

        &.loading {
            opacity: 0;
        }
    }

    .gameButtons {
        margin-top: 10px;

        > button {
            all: unset;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 200px;
            border: 1px solid $grey2;
            border-radius: 10px;
            background-color: white;
            padding: 20px;
            color: $grey7;
            transition: all 0.3s;

            &:not(:last-child) {
                margin-right: 20px;
            }

            &:hover {
                border: 1px solid $grey3;
                color: $black2;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
            }

            svg {
                width: 120px;
                height: 120px;
                margin-bottom: 20px;
            }
        }
    }
}

// .newButtons {
//     button {
//         all: unset;
//         cursor: pointer;
//         display: flex;
//         align-items: center;
//         color: $grey7;
//         transition: color 0.3s;

//         svg {
//             width: 25px;
//             height: 25px;
//             margin-right: 8px;
//         }

//         &:not(:last-child) {
//             margin-bottom: 8px;
//         }

//         &:hover {
//             color: $blue5;
//         }
//     }
// }

// .streamButton {
//     display: flex;
//     align-items: center;
//     margin-bottom: 8px;

//     > div {
//         width: 32px;
//         height: 32px;
//         background-image: linear-gradient(141deg, #9fb8ad 0%, #1fc8db 51%, #2cb5e8 75%);
//         border-radius: 50%;
//         color: white;
//         margin-right: 5px;

//         svg {
//             width: 20px;
//             height: 20px;
//         }
//     }
// }

// .divider {
//     height: 30px;
//     width: 2px;
//     background-color: $grey3;
//     border-radius: 2px;
//     margin-right: 10px;
//     flex-shrink: 0;
// }
