@import '@styles/Variables';

.wrapper {
    margin-bottom: 15px;
    padding: 25px 35px;
    width: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 7px 10px 0 rgba(10, 8, 72, 0.04);
    border: 1px solid $grey2;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;

    .PHPostBody {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
    }
    .PHPostTags {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
    .PHPostUserImage {
        background-color: rgba(0, 0, 0, 0.06);
        height: 32px;
        width: 32px;
        border-radius: 50%;
        flex-shrink: 0;
        opacity: 0.8;
    }
    .PHPostTagsBlock1 {
        height: 20px;
        width: 150px;
        background-color: rgba(0, 0, 0, 0.07);
        margin-left: 10px;
        opacity: 0.8;
    }
    .PHPostTagsBlock2 {
        height: 20px;
        width: 100px;
        background-color: rgba(0, 0, 0, 0.03);
        margin-left: 10px;
    }
    .PHPostTitle {
        height: 20px;
        width: 300px;
        background-color: rgba(0, 0, 0, 0.07);
        margin-bottom: 10px;
    }
    .PHPostDescription {
        height: 20px;
        width: 430px;
        background-color: rgba(0, 0, 0, 0.07);
        margin-bottom: 20px;
    }
    .PHPostInteract {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-shrink: 0;
        flex-wrap: wrap;
    }
    .PHPostInteractItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-shrink: 0;
        margin-right: 10px;
    }
    .PHPostInteractItemCircle {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.1);
        margin-right: 10px;
    }
    .PHPostInteractItemBlock {
        height: 15px;
        width: 60px;
        background-color: rgba(0, 0, 0, 0.05);
    }
}
