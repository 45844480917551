@import '@styles/Variables';

.wrapper {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 8px 10px;
    width: calc(100% - 20px);
    transition: background-color 0.3s;

    &:hover {
        background-color: $blue7;
    }

    > p {
        margin-left: 5px;
    }

    > span {
        margin-left: 5px;
        color: $grey5;
    }
}
