// todo: rename file to 'variables' and update imports

// old colors
$blueGrey: #3a88f0; //#0093ff; //#a9c1ea;
$blueGreyTransparent: #3a88f066;
$purple: #aa8dfa;
$purple-o: #aa8dfa66;
// post types
$blue: #aa8dfa;

// new
$white: #fff;
$grey0: #fcfcfc;
$grey1: #f7f7f9;
$grey2: #ededef;
$grey9: #e9e9e9;
$grey3: #dadadd;
$grey4: #c5c5c7;
$grey5: #acacae;
$grey6: #7f7f82;
$grey7: #424547;
$grey8: #aab3bf;
$bluegrey1: #e7f2fd;
$bluegrey2: #bbcce0;
$black1: #000;
$black2: #202129;
$black3: #303030;
$blue1: #82afff;
$blue2: #3a88f0;
$blue3: #28acff;
$blue4: #51a6dc;
$blue5: #44b1f7;
$blue7: #f1f9ff;
$blue6: #f6fbff;
$blue8: #ddf1ff;
$blue9: #bcd8f8;
$purple1: #826cff;
$purple2: #c8bffc;
$red: #ef0037;
$red2: #f15e75;
$lightred: #fff1f1;
$orange: #faa21d;
$green: #00e697;
$lightgreen: #eefff9;
$green2: #00daa2;
$green3: #5edd80;
$green3light: #91ffb3;
$aqua: #00b1a9;
$aqua2: #24c2ba;
$aquaLight: #a8e6e3;

// reactions
$likeRed: #e62b63;
$commentGreen: #1dd050;
$linkPurple: #7a66ed;
$ratingOrange: #faa21d;
$repostBlue: #44b1f7;
$gamePurple: #7a66ed;

// gradients
$aquaGradient: -webkit-linear-gradient(75deg, #51a7dc 0%, #63ccad 100%);

// post type colors
$postTypeText: $green3;
$postTypeUrl: $blue5;
$postTypeImage: $blue2;
$postTypeAudio: $orange;
$postTypeEvent: $red2;
$postTypeInquiry: $red;
$postTypeGlassBeadGame: $purple;
$postTypeString: $blue1;
$postTypePrism: $green2;
$postTypeMultiplayerString: $aqua;

// z-indexes
$fullScreenModalZIndex: 100;
$navbarZIndex: 90;
$sidebarSmallZIndex: 80;
$sidebarZIndex: 70;
$headerZIndex: 70;
$pageContentZIndex: 10;
$toybarZIndex: 80;

// layout
// todo: use numbers instead of pixels so can be used in calculations?
$navbarHeight: 60px;
$sidebarWidth: 230px;
$pageContentHorizontalPadding: 30px;
$pageContentVerticalPadding: 15px;
$coverImageHeight: 120px;

// breakpoint mixins
@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin for-post-width-up {
    @media (min-width: 800px) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media (min-width: 900px) {
        @content;
    }
}

@mixin for-desktop-small-up {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin for-desktop-medium-up {
    @media (min-width: 1500px) {
        @content;
    }
}

@mixin for-desktop-large-up {
    @media (min-width: 1800px) {
        @content;
    }
}
