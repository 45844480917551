@import '@styles/Variables';

.wrapper {
    margin-bottom: 10px;
    width: 100%;
    @include for-desktop-small-up {
        width: auto;
    }

    .spaceList {
        width: 100%;
        @include for-desktop-small-up {
            width: 750px;
        }
        @include for-desktop-medium-up {
            width: 800px;
        }
    }

    .spaceNavWrapper {
        flex-shrink: 0;
        position: sticky !important;
        top: 116px;
        left: 0;
        max-height: calc(100vh - 140px);
        min-width: 240px;
        z-index: 5;
    }

    .spaceMapView {
        width: 100%;
        min-width: calc(100vw - 20px);
        height: calc(100vh - 135px);
        padding: 0 10px;

        @include for-desktop-small-up {
            width: calc(100vw - 40px);
        }
    }
}
