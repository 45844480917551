@import '@styles/Variables';

.gameCard {
    margin-top: 15px;
    padding: 10px;
    border: 1px solid $grey2;
    border-radius: 10px;
    background-color: $grey1;
}

.icon {
    width: 14px;
    height: 14px;
    color: $grey4;
}

.stepsWrapper {
    margin-left: 10px;
}

.steps {
    padding-left: 5px;
}

.emptySteps {
    margin-top: 10px;
    margin-left: 30px;
    margin-bottom: 10px;

    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;

    background-color: $grey0;
    border-radius: 5px;
}


.stepWrapper {
    padding-top: 10px;


    &:not(:first-child)>.step>.stepBody {
        border-top: 1px solid $grey2;
    }

    .step {
        &>.addStepButton {
            padding-right: 5px;
            visibility: hidden;
        }

        &:hover>.addStepButton.editable {
            visibility: visible;
        }

        .stepBody {
            .stepHeader {
                cursor: pointer;



                .stepTitle {
                    flex-grow: 1;
                }

                .stepActions {
                    visibility: hidden;

                    button {
                        margin-left: 5px;
                    }
                }

                &:hover {
                    .stepActions {
                        visibility: visible;
                    }
                }
            }

            &:hover {
                .stepActions {
                    visibility: visible;
                }
            }
        }
    }
}
