@import '@styles/App.scss';

.wrapper {
    width: 100%;
    max-width: 930px;
    margin-bottom: 135px;

    .content {
        width: 100%;
        background-color: white;
        border-radius: 10px;
        padding: 40px;
        z-index: 1;

        .creation {
            margin-top: 60px;
            > p {
                margin-right: 5px;
            }
        }
    }
}
