@import '@styles/Variables';

.wrapper {
    position: relative;
    justify-content: flex-start;
    margin-top: 10px;
    opacity: 0;
    transition: opacity 0.1s;
    width: 100%;

    &.visible {
        opacity: 1;
    }

    &.isOwnComment {
        justify-content: flex-end;

        .message {
            background-color: $blue8;

            .reactions {
                left: 5px;
            }
        }
    }

    &:hover {
        .buttons {
            visibility: visible;
        }
    }

    &.move {
        justify-content: center;

        .buttons {
            display: none;
        }

        .message {
            max-width: none;
            flex-basis: 400px;
            flex-shrink: 1;
            border: 1px solid $green3;
            background-color: $green3light;
            border-radius: 0;
        }

        &.started,
        &.paused {
            .message {
                background-color: $green3;
                // flex-grow: 1;
                // max-width: none;

                .header {
                    p {
                        color: $grey6;
                    }
                }

                .content {
                    // text-align: center;
                }
            }

            .progressBar {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;

                .progress {
                    height: 5px;
                    background-color: $red2;
                }
            }
        }
    }

    .message {
        position: relative;
        max-width: calc(100% - 180px);
        background-color: $grey1;
        border-radius: 10px;
        padding: 10px 12px;
        border: 1px solid $grey2;
        flex-shrink: 0;
        overflow: hidden;

        @include for-desktop-small-up {
            max-width: 700px;
        }

        @include for-desktop-large-up {
            max-width: 900px;
        }

        .header {
            margin-bottom: 5px;

            p {
                color: $grey4;
            }
        }

        .parent {
            background-color: $blue9;
            border-radius: 5px;
            padding: 5px 10px;
            margin-bottom: 10px;
            border: 1px solid $blue5;

            h1 {
                font-size: 16px;
                font-weight: 800;
                margin: 0;
            }

            p {
                font-size: 14px;
            }
        }

        .content {
            border-radius: 10px;
            font-size: 14px;
        }

        .reactions {
            position: absolute;
            right: 5px;
            bottom: -15px;

            > button {
                all: unset;
                cursor: pointer;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px;
                border-radius: 12px;
                border: 1px solid $grey2;

                &:hover {
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                }

                &:not(:last-child) {
                    margin-right: 5px;
                }

                svg {
                    width: 14px;
                    height: 14px;
                }

                p {
                    font-size: 12px;
                    line-height: 12px;
                    margin-left: 2px;
                    color: $grey7;
                }

                &.red {
                    svg {
                        color: $likeRed;
                    }
                }

                &.purple {
                    svg {
                        color: $linkPurple;
                    }
                }

                &.orange {
                    svg {
                        color: $ratingOrange;
                    }
                }
            }
        }
    }

    .buttons {
        position: relative;
        visibility: hidden;
        margin: 0 10px;

        > button {
            all: unset;
            cursor: pointer;
            margin-right: 10px;

            &.red {
                svg {
                    color: $likeRed;
                }

                &:hover {
                    svg {
                        color: $likeRed;
                    }
                }
            }

            &.purple {
                svg {
                    color: $linkPurple;
                }
            }

            &.orange {
                svg {
                    color: $ratingOrange;
                }
            }
        }

        > a {
            margin: 0 7px 1px 2px;
        }

        > button,
        > a {
            width: 18px;

            svg {
                width: 18px;
                height: 18px;
                color: $grey4;
                transition: color 0.2s;
            }

            &:hover {
                svg {
                    color: $grey6;
                }
            }
        }

        .menu {
            position: absolute;
            top: calc(50% + 15px);
            right: 5px;
            background-color: white;
            box-shadow: 0 0 7px 0 rgba(black, 0.15);
            border-radius: 5px;
            padding: 10px;
            z-index: 10;

            button {
                all: unset;
                cursor: pointer;
                display: flex;
                align-items: center;
                font-size: 14px;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                &:hover > svg {
                    color: $grey6;
                }

                > svg {
                    width: 18px;
                    height: 18px;
                    margin-right: 6px;
                    color: $grey5;
                    transition: color 0.2s;
                }
            }
        }
    }
}
