@import '@styles/Variables';

$borderRadius: 5px;

.loading {
    width: 100%;
    border-radius: $borderRadius;
    background-color: $grey1;
    padding: 10px;
}

.wrapper {
    width: 100%;
    background-color: white;
    overflow: hidden;
    transition: background-color 0.3s;
    position: relative;
    border: 1px solid $grey2;
    border-radius: $borderRadius;

    &:hover {
        background-color: $blue7;
    }

    > a {
        display: flex;
        flex-direction: column;
        width: 100%;

        > img {
            width: 100%;
            height: 180px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            object-fit: cover;
            flex-shrink: 0;
            border-right: 1px solid $grey2;
            background-color: $grey2;
        }

        .urlIcon {
            margin: 12px 0 0 0;
            color: $grey6;
            > svg {
                width: 20px;
                height: 20px;
            }
        }

        p {
            word-break: break-all;
            overflow: hidden;
        }

        .metaData {
            padding: 10px 15px;

            h1 {
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                max-height: 40px;
                overflow: hidden;
                margin-bottom: 2px;
            }

            p {
                font-size: 14px;
                line-height: 18px;
                max-height: 36px;
                margin-bottom: 5px;
            }

            .domain {
                > svg {
                    width: 15px;
                    height: 15px;
                    opacity: 0.3;
                    margin-right: 5px;
                }

                > p {
                    margin: 0;
                    opacity: 0.5;
                }

                > img {
                    width: 20px;
                    height: 20px;
                    object-fit: cover;
                    margin-right: 5px;
                }
            }
        }

        @include for-tablet-portrait-up {
            flex-direction: row;

            > img {
                width: 240px;
                height: 140px;
                border-top-left-radius: $borderRadius;
                border-top-right-radius: 0;
                border-bottom-left-radius: $borderRadius;
                border-bottom-right-radius: 0;
            }

            .urlIcon {
                margin: 0 0 0 12px;
            }
        }
    }

    &.bead {
        height: 100%;
        width: 100%;
        margin: 0;
        border-radius: 5px;
        border: none;
        background-color: $grey1;

        &:hover {
            background-color: $grey2;
        }

        > a {
            flex-direction: column;
            justify-content: center;

            > img {
                width: 281px;
                border-bottom-left-radius: 0;
                flex-shrink: unset;
            }
        }
    }
}
