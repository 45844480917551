@import '@styles/Variables';

.wrapper {
    height: 100%;

    .spaces {
        padding-right: 10px;

        > div {
            > svg {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                color: $grey4;
            }

            > p {
                color: $grey5;
                margin-bottom: 10px;
            }
        }

        .expandButton {
            all: unset;
            cursor: pointer;
            color: $grey4;
            width: 20px;
            height: 20px;
            margin-left: 2px;
            display: flex;
            align-items: center;
            justify-content: center;

            > svg {
                width: 10px;
                height: 10px;
            }

            &:hover {
                color: $grey6;
            }
        }

        .children {
            margin-left: 15px;
            padding-right: 10px;
        }

        .loadMore {
            all: unset;
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 24px;
            width: 100%;
            margin-bottom: 15px;
            font-size: 14px;
            color: $blue1;
        }
    }
}
