@import '@styles/Variables';

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &.disabled > .inputWrapper {
        background-color: $blue6;
    }

    // title
    > h1 {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
        color: $black1;
    }

    // error message
    > h2 {
        font-size: 14px;
        font-weight: 400;
        color: $red;
        margin-bottom: 5px;
    }

    .inputWrapper {
        display: flex;
        align-items: center;
        background-color: $white;
        width: 100%;
        border-radius: 5px;
        position: relative;
        border: 1px solid $grey2;
        transition: box-shadow 0.4s;

        &:hover {
            box-shadow: 0 0 0 3px rgb(183, 214, 255);
        }

        // used for prefix
        > span {
            font-size: 14px;
            color: $grey4;
            padding: 0 0 0 15px;
            margin-right: -10px;
        }

        > input {
            font-size: 14px;
            height: 40px;
            width: 100%;
            padding: 0 15px;
            border-radius: inherit;
            background: none;
            outline: none;
            border: none;
            display: flex;
            align-items: center;
        }

        > textarea {
            font-size: 14px;
            width: 100%;
            padding: 10px 15px;
            border-radius: inherit;
            outline: none;
            border: none;
            display: flex;
            align-items: center;
            background-color: transparent;
        }

        ::placeholder {
            color: $grey6;
            opacity: 1;
        }

        // validation
        &.invalid {
            box-shadow: 0 0 0 2px rgba($red, 0.8);
            &:hover {
                box-shadow: 0 0 0 3px $red;
            }
            svg > path {
                fill: $red;
            }
        }

        &.valid {
            box-shadow: 0 0 0 2px rgba($aqua, 0.8);
            &:hover {
                box-shadow: 0 0 0 3px $aqua;
            }
            svg > path {
                fill: $aqua;
            }
        }

        &.invalid,
        &.valid {
            input {
                padding: 0 30px 0 15px;
            }
            textarea {
                padding: 10px 30px 10px 15px;
            }
        }

        .stateIcon {
            position: absolute;
            top: 10px;
            right: 10px;
            > svg {
                flex-shrink: 0;
                height: 20px;
                width: 20px;
            }
        }
    }

    &.number {
        input {
            padding: 0 5px 0 15px;
            text-align: center;
        }
    }
}
