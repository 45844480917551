@import '@styles/Variables';

.wrapper {
    margin-bottom: 5px;
    padding: 10px;
    width: 100%;
    background-color: white;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px 0 rgba(10, 8, 72, 0.04);
    border: 1px solid $grey2;

    &.unseen {
        background-color: $blue6;
    }

    @include for-tablet-portrait-up {
        padding: 20px 30px;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .seenButton {
        all: unset;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 15px;

        svg {
            width: 20px;
            height: 20px;
            color: $grey4;
        }

        @include for-tablet-portrait-up {
            right: 30px;
            top: 33px;

            svg {
                width: 25px;
                height: 25px;
            }
        }
    }

    .typeIcon {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        border-radius: 50%;
        background-color: $grey1;
        border: 1px solid $grey2;
        flex-shrink: 0;

        svg {
            width: 30px;
            height: 30px;
            color: $grey5;
        }
    }

    .content {
        width: 100%;
        $contentMargin: 5px;
        font-size: 14px;
        color: $grey4;
        margin-bottom: -$contentMargin;
        margin-right: 20px;
        align-items: center;

        .inlineIcon {
            width: 25px;
            height: 25px;
            &.green > svg > path {
                fill: $green2;
            }
            &.red > svg > path {
                fill: $red;
            }
        }

        .state {
            display: flex;
            align-items: center;
            > p {
                flex-shrink: 0;
                margin-right: 5px;
            }
        }

        > p {
            &.green {
                color: $green2;
            }
            &.red {
                color: $red;
            }
        }

        > a,
        > p,
        > div,
        > button {
            flex-shrink: 0;
            margin: 0 $contentMargin $contentMargin 0;
        }
    }
}
