@import '@styles/Variables';

.wrapper {
    all: unset;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.clickable {
        cursor: pointer;
    }

    > p {
        margin-left: 10px;
    }
}
