@import '@styles/Variables';

.wrapper {
    display: flex;
    align-items: center;
    max-width: 400px;
    color: $green3;
    > svg {
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        margin-right: 10px;
    }
}
