@import '@styles/Variables';

.wrapper {
    height: 100%;

    .id {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    > div {
        .navButton {
            all: unset;
            cursor: pointer;

            > svg {
                width: 20px;
                height: 20px;
                color: $grey3;
                &:hover {
                    color: $grey4;
                }
                transition: color 0.5s;

                @include for-tablet-landscape-up {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        > div {
            margin: 0 5px;
            @include for-tablet-landscape-up {
                margin: 0 10px;
            }

            > img {
                max-width: 100%;
                max-height: calc(100% - 90px);

                @include for-desktop-small-up {
                    max-height: calc(100vh - 250px);
                }
            }
        }
    }
}
