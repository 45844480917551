.PHHolon {
    margin-bottom: 10px;
    padding: 20px 30px;
    width: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 1px 10px 0 rgba(10, 8, 72, 0.05);
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
}
.PHHolonImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
    opacity: 0.5;
    outline: none;
    background-color: rgba(0, 0, 0, 0.06);
    flex-shrink: 0;
}
.PHHolonInfo {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.PHHolonTitle {
    width: 150px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.06);
    margin-bottom: 10px;
}
.PHHolonDescription1 {
    width: 350px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.04);
    margin-bottom: 10px;
}
.PHHolonDescription2 {
    width: 300px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.04);
}
