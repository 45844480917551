@import '@styles/Variables';
@import '@styles/Spacing';
@import '@styles/Transitions';
@import '@styles/Placeholders';
@import '@styles/DarkMode';

* {
    box-sizing: border-box;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}

html {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
}

body {
    background-color: #f7f7f9;
    width: 100%;
}

#root {
    width: 100%;
}

.scrollbar-theme {
    $scrollBarThickness: 8px;

    .os-viewport {
        z-index: unset;
    }

    .os-scrollbar-horizontal {
        height: $scrollBarThickness;
        background-color: $grey2;
        border-radius: $scrollBarThickness / 2;
        .os-scrollbar-track {
            .os-scrollbar-handle {
                height: $scrollBarThickness;
                background-color: $grey4;
                border-radius: $scrollBarThickness / 2;
            }
        }
    }

    .os-scrollbar-vertical {
        width: $scrollBarThickness;
        background-color: $grey2;
        border-radius: $scrollBarThickness / 2;
        .os-scrollbar-track {
            .os-scrollbar-handle {
                width: $scrollBarThickness;
                background-color: $grey4;
                border-radius: $scrollBarThickness / 2;
            }
        }
    }
}

.flatpickr-calendar {
    position: fixed !important;
    top: calc(100vh / 2 - 168px) !important;
    left: calc(100vw / 2 - 154px) !important;
}

.flatpickr-calendar::before {
    content: none !important;
}

.flatpickr-calendar::after {
    content: none !important;
}

h1 {
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 20px;
}

h2 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 15px;
}

h3 {
    font-size: 18px;
    font-weight: 400;
}

a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

ul {
    padding: 0;
}

.roboto {
    font-family: 'Roboto', sans-serif;
}

.success {
    color: $green;
}

.danger {
    color: $red;
}

input {
    background-color: transparent;
}

.hide-scrollbars {
    // Hide scroll bars on Firefox:
    scrollbar-width: none;

    // Hide scroll bars on Microsoft Edge:
    -ms-overflow-style: none;

    // Hide scroll bars on Chrome:
    ::-webkit-scrollbar {
        display: none;
    }
}

.grey {
    color: $grey4;
}

.blueText {
    color: rgb(104, 161, 247);
    &:hover {
        cursor: pointer;
    }
}

// post map styles
.post-map-node {
    &:hover {
        cursor: pointer;
    }
}
.post-map-node-text {
    &:hover {
        cursor: pointer;
    }
}

// global styles
.warningContainer {
    border: 2px solid $red;
    border-radius: 10px;
    padding: 10px 20px;
    background-color: $lightred;
}

.successContainer {
    border: 2px solid $green;
    border-radius: 10px;
    padding: 10px 20px;
    background-color: $lightgreen;
}

.grecaptcha-badge {
    visibility: hidden;
}
