@import '@styles/Variables';

.searchBar {
    position: relative;
    background-color: #434348; //$grey2;
    height: 40px;
    width: 500px;
    border-radius: 20px;
    padding: 0px 15px 0 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 1px 10px 0 rgba(10, 8, 72, 0.04);
    transition-property: box-shadow;
    transition-duration: 0.3s;

    @include for-desktop-small-up {
        width: 700px;
    }

    &:hover {
        box-shadow: 0 1px 10px 0 rgba(10, 8, 72, 0.1);
    }

    .searchConstraint {
        background-color: $blue9;
        height: 28px;
        padding: 0 10px 0 5px;
        border-radius: 14px;
        flex-shrink: 0;

        p {
            font-size: 14px;
            line-height: 14px;
        }

        button {
            color: $grey6;
            margin-left: 5px;
            opacity: 0.7;
        }
    }

    > input {
        width: 100%;
        font-size: 14px;
        outline: none;
        border: none;
        padding: 0;
        margin-left: 7px;
        color: white;
    }

    > input::placeholder {
        color: $grey5;
    }

    > button {
        all: unset;
        cursor: pointer;
        width: 20px;
        height: 20px;

        > svg {
            width: 20px;
            height: 20px;
            opacity: 0.3;
        }
    }

    .searchOptions {
        position: absolute;
        top: 50px;
        left: -2px;
        background-color: $black2;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 10px 15px;
        box-shadow: 0 0 10px 0 rgba(10, 8, 72, 0.05);
        width: max-content;

        > button {
            all: unset;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-bottom: 7px;
            font-size: 14px;
            > p {
                color: white;
                margin: 0 5px;
            }
            > span {
                color: rgb(160, 160, 160);
            }
        }
    }
}
