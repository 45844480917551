@import '@styles/Variables';

.wrapper {
    width: 100%;
    margin-top: 60px;

    .header {
        width: 100%;
        background-color: white;
        border-bottom: 2px solid $grey2;
        box-shadow: 0 -5px 15px 0 rgba(black, 0.2);
        z-index: 50;
        position: sticky;
        top: -$coverImageHeight - 5px;

        &.collapsed {
            .tabRow {
                height: 40px;
                .userDataSmall {
                    opacity: 1;
                }
            }
        }

        .userData {
            position: relative;
            padding: 5px 0 5px 15px;
            margin-left: 120px;

            .flagImageLarge {
                position: absolute;
                top: -55px;
                left: -120px;
            }

            .userName {
                min-height: 50px;
                margin: 0 20px 0 0;

                > h1 {
                    margin-bottom: 3px;
                    line-height: 30px;
                }
            }
        }

        .tabRow {
            width: 100%;
            height: 10px;
            align-items: flex-end;
            position: relative;
            z-index: 2;
            transition: height 0.2s;

            @include for-tablet-landscape-up {
                height: 40px;
            }

            .userDataSmall {
                margin-bottom: 5px;
                z-index: 1;
                opacity: 0;
                transition: opacity 0.3s;
                white-space: nowrap;
                max-width: 100%;

                @include for-tablet-landscape-up {
                    position: absolute;
                    left: 10px;
                    top: 4px;
                    margin-bottom: 0;
                }

                p {
                    font-size: 18px;
                    margin: 0 7px;
                }

                span {
                    font-size: 16px;
                    color: $grey3;
                }
            }
        }
    }

    .content {
        width: 100%;
        min-height: calc(100vh - 103px);
        padding-top: 15px;
    }
}
