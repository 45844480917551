@import '@styles/Variables';

.post {
    width: 100%;
    background-color: white;
    box-shadow: 0 7px 10px 0 rgba(10, 8, 72, 0.04);
    position: relative;
    padding: 25px 15px;
    cursor: move;
    opacity: 0;
    transition: opacity 0.1s, border 0.5s, background-color 0.5s;

    @include for-tablet-portrait-up {
        border: 1px solid $grey2;
    }

    &.visible {
        opacity: 1;
    }

    &.dragging {
        background-color: rgb(237, 246, 251);
    }

    &.styling {
        cursor: pointer;
        border: 2px solid rgba(0, 0, 0, 0.1);
        box-shadow: none;

        &:hover {
            background-color: rgb(249, 253, 255); //white;
            border: 2px solid rgba(0, 0, 0, 0.35);
        }

        @include for-tablet-portrait-up {
            padding: 25px 35px 15px 35px;
            border-radius: 0;
        }
    }

    @include for-tablet-portrait-up {
        padding: 25px 35px 15px 35px;
        border-radius: 10px;
    }

    .rootLink,
    .parentLink {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 15px;
        padding: 0 10px;

        &:not(:last-child) {
            margin-right: 20px;
        }

        >svg {
            width: 16px;
            height: 16px;
            color: $grey7;
        }
    }

    .rootLink {
        background-color: $purple2;
    }

    .parentLink {
        background-color: $blue8;
    }

    .header {
        font-size: 15px;
        margin-bottom: 10px;
        overflow: hidden;
        padding: 2px;

        @include for-tablet-portrait-up {
            overflow: visible;
            padding: 0;
        }

        .id {
            display: flex;
            align-items: center;
        }

        .menuButton {
            all: unset;
            cursor: pointer;
            padding-left: 10px;
            height: 32px;
            display: flex;
            align-items: center;

            &:hover {
                >svg {
                    color: $grey6;
                }
            }

            >svg {
                color: $grey4;
                width: 10px;
                height: 25px;
                transition: color 0.3s;
            }
        }

        .menu {
            position: absolute;
            top: 65px;
            right: 35px;
            background-color: white;
            box-shadow: 0 0 7px 0 rgba(black, 0.15);
            border-radius: 10px;
            padding: 12px;
            z-index: 10;

            button {
                all: unset;
                cursor: pointer;
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                &:hover>svg {
                    color: $grey6;
                }

                >svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                    color: $grey5;
                    transition: color 0.3s;
                }
            }
        }
    }

    .content {
        width: 100%;

        .sourcePostId {
            margin-bottom: 20px;

            >a {
                display: flex;
                background-color: $grey1;
                padding: 12px;
                border-radius: 10px;
                line-height: 16px;

                span {
                    color: $grey6;
                    margin-right: 5px;
                }
            }
        }

        .title {
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 10px;
            // cursor: text;
        }

        .topic {
            margin-bottom: 10px;

            img {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                margin-right: 10px;
                flex-shrink: 0;
                box-shadow: 0 0 10px 0 rgba(10, 8, 72, 0.1);
                object-fit: cover;
            }

            h1 {
                font-size: 24px;
                margin: 0;
            }
        }

        .image {
            max-width: 100%;
            max-height: 450px;
            object-fit: scale-down;
            margin-bottom: 20px;
        }
    }

    .footer {
        $backgroundOpacity: 0.1;
        margin: 0 -8px;

        .reactions {

            // like button styled seperately due to different html
            .like {
                $color: $likeRed;
                margin-right: 14px;

                &:hover,
                &.highlighted {
                    >button {
                        color: $color;

                        &:first-child {
                            background-color: rgba($color, $backgroundOpacity);
                        }
                    }
                }

                >button {
                    all: unset;
                    cursor: pointer;
                    color: $grey5;
                    transition: background-color 0.5s;

                    &:first-child {
                        width: 34px;
                        height: 34px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 3px;
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                        transition: color 0.3s;
                    }

                    p {
                        transition: color 0.3s;
                    }
                }
            }

            >button {
                all: unset;
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-right: 14px;
                color: $grey5;

                >div {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    margin-right: 3px;
                    transition: background-color 0.5s;

                    svg {
                        width: 20px;
                        height: 20px;
                        transition: color 0.3s;
                    }
                }

                >p {
                    transition: color 0.3s;
                }

                &.comment {
                    $color: $commentGreen;

                    &:hover,
                    &.highlighted {
                        color: $color;

                        >div {
                            background-color: rgba($color, $backgroundOpacity);
                        }
                    }
                }

                &.link {
                    $color: $linkPurple;

                    &:hover,
                    &.highlighted {
                        color: $color;

                        >div {
                            background-color: rgba($color, $backgroundOpacity);
                        }
                    }
                }

                &.rating {
                    $color: $ratingOrange;

                    &:hover,
                    &.highlighted {
                        color: $color;

                        >div {
                            background-color: rgba($color, $backgroundOpacity);
                        }
                    }
                }

                &.repost {
                    $color: $repostBlue;

                    &:hover,
                    &.highlighted {
                        color: $color;

                        >div {
                            background-color: rgba($color, $backgroundOpacity);
                        }
                    }
                }

                &.game {
                    $color: $gamePurple;

                    &:hover,
                    &.highlighted {
                        color: $color;

                        >div {
                            background-color: rgba($color, $backgroundOpacity);
                        }
                    }
                }
            }
        }

        .linkPost {
            all: unset;
            cursor: pointer;
            display: flex;
            align-items: center;
            color: $grey5;

            &:hover,
            &.highlighted {
                $color: $linkPurple;
                color: $color;

                >div {
                    background-color: rgba($color, $backgroundOpacity);
                }
            }

            >div {
                width: 34px;
                height: 34px;
                border-radius: 50%;
                margin-right: 3px;
                transition: background-color 0.5s;

                svg {
                    width: 23px;
                    height: 23px;
                    transition: color 0.3s;
                }
            }
        }
    }
}

// .watermark {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: inherit;
//     height: 100%;
//     background-color: white;
//     border: 2px solid white;
//     border-radius: inherit;
//     opacity: 0.5;
//     // z-index: 1;
//     pointer-events: none;
// }

// .postType {
//     border-radius: 50%;
//     width: 32px;
//     height: 32px;
//     margin-left: 5px;
//     flex-shrink: 0;

//     > svg {
//         width: 18px;
//         height: 18px;
//         color: inherit;
//     }

//     &.text {
//         color: $postTypeText;
//         border: 2px solid $postTypeText;
//     }
//     &.url {
//         color: $postTypeUrl;
//         border: 2px solid $postTypeUrl;
//     }
//     &.image {
//         color: $postTypeImage;
//         border: 2px solid $postTypeImage;
//     }
//     &.audio {
//         color: $postTypeAudio;
//         border: 2px solid $postTypeAudio;
//     }
//     &.event {
//         color: $postTypeEvent;
//         border: 2px solid $postTypeEvent;
//     }
//     &.poll {
//         color: $postTypeInquiry;
//         border: 2px solid $postTypeInquiry;
//     }
//     &.glass-bead-game {
//         color: $postTypeGlassBeadGame;
//         border: 2px solid $postTypeGlassBeadGame;
//         > svg {
//             width: 22px;
//             height: 22px;
//         }
//     }
//     &.string {
//         color: $postTypeString;
//         border: 2px solid $postTypeString;
//         > svg {
//             width: 24px;
//             height: 24px;
//         }
//     }
//     &.weave {
//         color: $postTypeMultiplayerString;
//         border: 2px solid $postTypeMultiplayerString;
//     }
//     &.prism {
//         color: $postTypePrism;
//         border: 2px solid $postTypePrism;
//     }
// }

// .pinned {
//     background-color: #f1f6ff;
// }

// .pinFlag {
//     background-image: url(`${config.publicAssets}/icons/pin-01.png`);
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-color: transparent;
//     border: none;
//     height: 17px;
//     width: 17px;
//     padding: 0;
//     opacity: 0.4;
//     margin-right: 5px;
//     position: absolute;
//     right: 15px;
//     &:hover {
//         cursor: pointer;
//     }
// }
