@import '@styles/Variables';

.wrapper {
    // width: calc(100vw - 40px);
    @include for-tablet-landscape-up {
        padding: 50px;
        // width: auto;
    }

    .postCard {
        width: 100%;
        background-color: white;
        box-shadow: 0 7px 10px 0 rgba(10, 8, 72, 0.04);
        border: 1px solid $grey2;
        position: relative;
        opacity: 1;
        padding: 20px;
        border-radius: 0;
        margin-bottom: 20px;

        @include for-tablet-landscape-up {
            width: 780px;
            border-radius: 10px;
        }

        .header {
            font-size: 15px;
            line-height: 15px;
            margin-bottom: 10px;
        }

        .title {
            margin-bottom: 5px;

            > input {
                all: unset;
                width: 100%;
                font-size: 24px;
            }

            > button {
                pointer-events: none;
                opacity: 0;
                transition: all 0.5s;
            }

            &:hover {
                > button {
                    pointer-events: all;
                    opacity: 1;
                }
            }

            ::placeholder {
                color: $grey4;
                // opacity: 1;
            }
        }
    }
}
