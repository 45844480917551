@import '@styles/Variables';

.filters {
    padding: 0 10px;
    width: 100%;

    .button {
        all: unset;
        position: relative;
        cursor: pointer;
        background-color: white;
        display: flex;
        align-items: center;
        height: 30px;
        margin-bottom: 10px;
        padding: 0 10px;
        border-radius: 15px;
        box-shadow: 0 0 10px 0 rgba(black, 0.04);
        font-size: 12px;
        font-weight: 800;
        color: $grey6;
        transition: color 0.3s;

        &:hover {
            color: $grey7;
        }

        > svg {
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }

        &.selected {
            color: $blue5;
        }
    }

    .dropDown {
        position: absolute;
        top: 35px;
        left: 0;
        z-index: 5;
        box-shadow: 0 0 10px 0 rgba(black, 0.1);
        background-color: white;
        border-radius: 8px;
        padding: 5px 7px;
        width: max-content;

        > button {
            all: unset;
            cursor: pointer;
            display: flex;
            padding: 3px;
            font-size: 12px;
            font-weight: 800;
            color: $grey6;
            transition: color 0.3s;

            &:hover {
                color: $grey7;
            }

            > svg {
                width: 16px;
                height: 16px;
                margin-right: 5px;
            }

            > p {
                display: inline;
            }

            &.selected {
                color: $blue5;
            }
        }
    }

    .divider {
        height: 30px;
        width: 2px;
        background-color: $grey2;
        border-radius: 1px;
        margin-right: 10px;
    }
}
