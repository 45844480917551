@import '@styles/Variables';

.wrapper {
    width: 100%;
    position: relative;

    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: visible;
        transition: max-height 0.25s;
        width: 100%;

        .gradient {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            &.white {
                background-image: linear-gradient(to bottom, transparent, white);
            }

            &.grey {
                background-image: linear-gradient(to bottom, transparent, $grey1);
            }

            &.blue {
                background-image: linear-gradient(to bottom, transparent, $blue7);
            }
        }
    }

    .button {
        all: unset;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        &.expanded {
            transform: rotate(180deg);
            height: 20px;
        }

        &:hover {
            > svg {
                color: $grey6;
            }
        }

        > svg {
            color: $grey4;
            height: 20px;
            width: 20px;
            transition: color 0.3s;
        }
    }
}
