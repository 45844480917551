@import '@styles/Variables';

.wrapper {
    width: 100%;
    margin-bottom: 110px;
    position: relative;

    .posts > div,
    .spaces > div {
        margin-bottom: 2px;
    }

    @include for-tablet-portrait-up {
        padding: 0 10px;
        .posts > div,
        .spaces > div {
            margin-bottom: 15px;
        }
    }
    @include for-post-width-up {
        width: 800px;
    }
    @include for-tablet-landscape-up {
        min-height: calc(100vh - 220px);
    }
    @include for-desktop-medium-up {
        min-height: calc(100vh - 320px);
    }

    .noResults {
        padding: 40px;
        background-color: white;
        border-radius: 10px;
    }

    &.dragOver {
        background-color: rgba(0, 157, 255, 0.046);
    }
}
