@import '@styles/Variables';

.wrapper {
    margin-bottom: 10px;
    width: 100%;
    @include for-desktop-small-up {
        width: auto;
    }

    .postList {
        width: 100%;
        @include for-desktop-small-up {
            width: 750px;
        }
        @include for-desktop-medium-up {
            width: 800px;
        }
    }

    .spaceNavWrapper {
        flex-shrink: 0;
        position: sticky !important;
        top: $coverImageHeight - 12px;
        left: 0;
        max-height: calc(100vh - 240px);
        min-width: 240px;
        z-index: 5;
    }

    .roomHeader {
        margin-bottom: 10px;

        p {
            color: $grey5;
        }

        svg {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            color: $grey4;
        }
    }

    .topContributorsWrapper {
        flex-shrink: 0;
        position: sticky !important;
        top: $coverImageHeight - 12px;
        left: 0;
        max-height: calc(100vh - 240px);
        min-width: 260px;
        margin-left: 20px;
        z-index: 5;
    }

    .postMapView {
        width: 100%;
        min-width: calc(100vw - 20px);
        padding: 0 10px;

        @include for-desktop-small-up {
            width: calc(100vw - 40px);
        }
    }
}
