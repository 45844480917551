@import '@styles/Variables';

.wrapper {
    width: 100%;
    // margin: 70px 0 130px 0;
    margin: 70px 0 500px 0;
}
.postCardWrapper {
    width: 100%;
    max-width: 800px;
    @include for-tablet-portrait-up {
        width: calc(100% - 20px);
    }
}
