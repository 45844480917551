@import '@styles/Variables';

$borderRadius: 10px;

.wrapper {
    position: absolute;
    pointer-events: none;
    top: 50px;
    z-index: 5;
    opacity: 1;
    transition: opacity 0.5s;

    &.transparent {
        opacity: 0;
    }

    .modal {
        position: fixed;
        width: 280px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(10, 8, 72, 0.1);
        border-radius: $borderRadius;

        .coverImage {
            width: 100%;
            height: 60px;
            background-size: cover;
            background-position: center;
            border-top-left-radius: $borderRadius;
            border-top-right-radius: $borderRadius;
            flex-shrink: 0;
        }

        .flagImage {
            margin: -40px 0 5px 0;
        }

        h1 {
            font-size: 20px;
            line-height: 22px;
            margin-bottom: 3px;
            text-align: center;
        }

        h2 {
            font-size: 16px;
            color: $grey4;
            margin-bottom: 10px;
        }

        .stat {
            color: $grey4;

            &:not(:last-child) {
                margin-right: 15px;
            }

            svg {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }
    }
}
