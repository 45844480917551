@import '@styles/Variables';

$borderRadius: 10px;

.wrapper {
    min-width: 200px;
    max-width: 230px;
    min-height: 300px;
    margin: 0 15px 15px 0;
    border-radius: $borderRadius;
    background-color: white;
    box-shadow: 0 1px 10px 0 rgba(10, 8, 72, 0.05);
    flex-grow: 1;
    text-align: center;
    overflow: hidden;

    .content {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .coverImage {
        width: 100%;
        height: 100px;
        background-size: cover;
        background-position: center;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        flex-shrink: 0;
    }

    .flagImage {
        width: 100%;
        height: 120px;
        margin-top: -55px;
        display: flex;
        justify-content: center;
    }

    .textContent {
        width: 200px;
        padding: 10px;
        word-wrap: break-word;

        h1 {
            font-size: 22px;
            margin: 0;
            overflow-wrap: break-word;
        }

        h2 {
            font-size: 16px;
            color: $grey4;
            overflow-wrap: break-word;
        }
    }

    .footer {
        padding: 15px;
        color: $grey5;

        > button {
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
}
