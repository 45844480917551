@import '@styles/Variables';

.wrapper {
    position: absolute;
    width: calc(100% - 20px);
    margin-bottom: 800px;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s;
    z-index: 10;

    .gradient {
        position: absolute;
        opacity: inherit;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(247, 247, 249, 1));
        z-index: 1;
    }
}
