@import '@styles/Variables';

.wrapper {
    > p {
        flex-shrink: 0;
    }

    .otherSpaces {
        all: unset;
        border-radius: 50%;
        background-color: $grey1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin: 0 7px 0 2px;

        > p {
            font-size: 14px;
            font-weight: 800;
            margin: 0;
            color: $grey5;
            transition: color 0.3s;
        }

        &.button {
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: $grey2;
                > p {
                    color: $grey6;
                }
            }
        }
    }
}
