// Margin classes
.mt-5 {
    margin-top: 5px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-50 {
    margin-top: 50px;
}
.mt-70 {
    margin-top: 70px;
}
.mt-100 {
    margin-top: 100px;
}

.mr-5 {
    margin-right: 5px;
}
.mr-10 {
    margin-right: 10px;
}
.mr-20 {
    margin-right: 20px;
}

.mb-5 {
    margin-bottom: 5px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-50 {
    margin-bottom: 50px;
}
.mb-100 {
    margin-bottom: 100px;
}

.m-0 {
    margin: 0 !important;
}

.ml-5 {
    margin-left: 5px;
}
.ml-10 {
    margin-left: 10px;
}
.ml-20 {
    margin-left: 20px;
}
