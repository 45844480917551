@import '@styles/App.scss';

.wrapper {
    width: 100%;
    max-width: 930px;
    padding: 0 15px;
    margin-bottom: 135px;

    .content {
        width: 100%;
        background-color: white;
        border-radius: 10px;
        padding: 40px;

        .header {
            margin-bottom: 30px;

            h1 {
                margin: 0;
            }
        }
    }
}
