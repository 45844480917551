@import '@styles/Variables';

.post {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(10, 8, 72, 0.04);
    border: 1px solid $grey2;
    border-radius: 10px;
    margin-top: 10px;
    padding: 15px;
    color: black;
    background-color: white;
    transition: background-color 0.3s;

    &:hover {
        background-color: $blue8;
    }

    .header {
        font-size: 15px;
        overflow: hidden;
        padding: 2px;

        @include for-tablet-portrait-up {
            overflow: visible;
            padding: 0;
        }

        .time {
            margin-left: 2px;
            font-size: 14px;
        }
    }

    .content {
        width: 100%;
        overflow: hidden;

        .block {
            margin-top: 10px;
            background-color: $grey1;
            padding: 8px;
            border-radius: 5px;
            border: 1px solid $grey2;
            width: 100%;
        }

        .title {
            height: 26px;
            font-size: 24px;
            line-height: 24px;
            margin-top: 10px;
            overflow: hidden;
        }

        .text {
            height: 18px;
            font-size: 16px;
            line-height: 16px;
            margin-top: 5px;
            overflow: hidden;
        }

        .url {
            > img {
                width: 80px;
                height: 50px;
                margin-right: 10px;
                border-radius: 5px;
                object-fit: cover;
            }

            > svg {
                width: 22px;
                height: 22px;
                color: $grey5;
                margin-right: 10px;
            }

            .urlTitle {
                font-weight: 800;
                font-size: 16px;
                line-height: 20px;
                height: 20px;
                overflow: hidden;
            }

            .description {
                font-size: 16px;
                line-height: 20px;
                height: 20px;
                overflow: hidden;
            }
        }

        .image {
            > img {
                width: 100%;
                max-width: 300px;
                max-height: 200px;
                border-radius: 5px;
                object-fit: cover;
            }
        }

        .gbg {
            margin-top: 5px;

            > img {
                width: 36px;
                height: 36px;
                margin-right: 5px;
                border-radius: 50%;
                border: 1px solid $grey2;
                object-fit: cover;
            }
        }

        .typeIcon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $grey1;
            border: 1px solid $grey2;
            margin-right: 10px;
            margin-top: 5px;
            flex-shrink: 0;

            svg {
                width: 22px;
                height: 22px;
                color: $grey5;
            }
        }
    }
}

// .card {
//     margin-top: 10px;

//     .cardFace {
//         width: 40px;
//         height: 56px;
//         border-radius: 3px;
//         box-shadow: 0 0 6px 0 rgba(10, 8, 72, 0.18);
//         overflow: hidden;
//         margin-right: 15px;

//         > img {
//             width: inherit;
//             height: inherit;
//             object-fit: cover;
//         }
//     }
// }
