@import '@styles/Variables';

$transitionSpeed: 0.5s;

.wrapper {
    position: relative;
    width: 100%;
    // padding: 5px 0;

    span {
        font-family: inherit;
        line-height: 22px;
    }

    a {
        color: $blue1;
    }

    blockquote {
        border-left: 5px solid $grey2;
        padding-left: 10px;
        color: $grey6;
    }

    // code blocks
    pre {
        font-family: monospace;
        overflow-x: hidden;

        > div {
            margin: 10px 0;
            padding: 10px;
            border-radius: 5px;
            background-color: $grey2;
            font-family: inherit;
            line-height: 20px;
        }
    }

    ol,
    ul {
        padding-inline-start: 40px;
    }

    img {
        max-width: 100%;
    }

    .reverseColumn {
        // used to display toolbar above editor but load it after (to prevent button '.active' class bug)
        flex-direction: column-reverse;
    }

    // editor styling
    &.editable {
        background-color: white;
        padding: 0;

        :global(.DraftEditor-root) {
            position: relative;
            width: 100%;
            cursor: text;

            :global(.DraftEditor-editorContainer) {
                padding: 5px 0;
            }

            :global(.public-DraftEditorPlaceholder-root) {
                padding: 5px 0;
                color: $grey4;
                // text-shadow: 0 0 2px rgba(black, 0.2);
                position: absolute;
                pointer-events: none;
            }
        }
    }

    .toolbarWrapper {
        height: 37px;
        opacity: 1;

        .toolbar {
            width: 100%;
            display: flex;
            z-index: 2;

            > div {
                display: flex;
            }

            .button {
                border: 1px solid $grey2;
                border-radius: 5px;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                pointer-events: auto;
                height: 22px;
                width: 22px;
                margin: 0 2px 2px 0;
                padding: 3px;

                @include for-tablet-landscape-up {
                    height: 32px;
                    width: 32px;
                    margin: 0 5px 5px 0;
                    padding: 5px;
                }

                &:hover {
                    background-color: $grey1;
                }

                > svg {
                    width: 18px;
                }

                &.active {
                    background-color: $grey2;
                }
            }

            .separator {
                width: 2px;
                height: 32px;
                background-color: $grey2;
                margin: 0 10px 0 5px;
            }

            .linkInput {
                width: 100%;
                padding: 6px 8px;
                outline: none;
                border: 1px solid $grey2;
                background-color: white;
                border-radius: 5px;
                margin-bottom: 5px;
            }

            .imageInput {
                position: absolute;
                width: 100%;
                height: 32px;
                margin-bottom: 5px;
                background-color: white;

                input {
                    width: 100%;
                    height: 32px;
                    padding: 6px 8px;
                    outline: none;
                    border: 1px solid $grey2;
                    background-color: white;
                    border-radius: 5px;
                    margin-right: 10px;
                }

                .addImageButton {
                    all: unset;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $aqua;
                    width: 100px;
                    border-radius: 16px;
                    color: white;
                    font-size: 14px;
                    line-height: 14px;
                    padding: 0 5px;
                }
            }
        }
    }

    .characters {
        justify-content: end;
        width: 100%;
        height: 20px;
        opacity: 1;

        p {
            text-align: end;
            font-size: 14px;
            color: $grey4;

            &.error {
                color: $red;
            }
        }
    }

    &.post {
        .toolbarWrapper {
            height: 0;
            opacity: 0;
            transition: all $transitionSpeed;

            .toolbar {
                display: none;
                .button {
                    pointer-events: none;
                }
            }

            &.visible {
                height: 37px;
                opacity: 1;

                .toolbar {
                    display: flex;
                    .button {
                        pointer-events: auto;
                    }
                }
            }
        }

        .characters {
            height: 0;
            opacity: 0;
            transition: all $transitionSpeed;

            &.visible {
                height: 20px;
                opacity: 1;
            }
        }
    }

    &.comment {
        :global(.DraftEditor-root) {
            padding: 5px 10px 5px 10px;
            width: calc(100% - 90px);
        }

        :global(.DraftEditor-editorContainer) {
            background-color: $grey1;
            border-radius: 10px;
            padding: 12px !important;
        }

        :global(.public-DraftEditorPlaceholder-root) {
            padding: 12px !important;
            // height: ;
        }

        .toolbar {
            border-bottom: none;
        }

        .characters {
            margin-bottom: 5px;
        }

        &.invalid {
            :global(.DraftEditor-root) {
                border: none;
                padding-bottom: 0;
            }

            :global(.DraftEditor-editorContainer) {
                border: 2px solid $red;
            }

            .stats {
                border: none;
                margin-bottom: 5px;
                padding: 0;
            }
        }
    }

    &.bead {
        height: 400px;
        background-color: transparent;

        .reverseColumn {
            align-items: center;
            margin-bottom: 34px;

            .toolbarWrapper {
                justify-content: center;
                height: 50px;
                margin-bottom: 36px;

                .linkInput {
                    height: 37px;
                    width: 300px;
                }
            }

            .editorWrapper {
                width: 274px;
                height: 244px;
                justify-content: center;
                align-items: center;

                :global(.DraftEditor-editorContainer) {
                    max-height: 244px;
                }
            }
        }

        .characters {
            justify-content: center;
        }
    }

    &.card {
        width: 482px;
        background-color: transparent;
        margin-top: -42px;

        .reverseColumn {
            align-items: center;
            margin-bottom: 20px;

            .toolbarWrapper {
                justify-content: center;
                height: 40px;
                margin-bottom: 20px;
                opacity: 0;
                transition: all $transitionSpeed;

                .linkInput {
                    height: 37px;
                    width: 300px;
                }

                .toolbar {
                    .button {
                        pointer-events: none;
                    }

                    .imageInput {
                        background-color: $grey1;
                    }
                }

                &.visible {
                    opacity: 1;

                    .toolbar {
                        display: flex;
                        .button {
                            pointer-events: auto;
                        }
                    }
                }
            }

            .editorWrapper {
                width: 220px;
                height: 320px;
                justify-content: center;
                align-items: center;

                :global(.DraftEditor-editorContainer) {
                    max-height: 320px;
                    overflow: hidden;
                }
            }
        }

        .characters {
            justify-content: center;
            height: 20px;
            opacity: 0;
            transition: all $transitionSpeed;

            &.visible {
                opacity: 1;
            }
        }
    }

    &.other {
        padding: 10px;

        :global(.DraftEditor-root) {
            margin: 5px 0;
        }
    }

    .alignLeft {
        text-align: left;
    }

    .alignRight {
        text-align: right;
    }

    .alignCenter {
        text-align: center;
    }

    // .stats {
    //     background-color: white;
    //     padding: 5px 0;
    //     // border: 1px solid $grey2;
    //     font-size: 14px;
    //     color: $grey4;
    //     // height: 30px;

    //     &.error {
    //         color: $red;
    //     }

    //     .errors {
    //         > svg {
    //             flex-shrink: 0;
    //             height: 20px;
    //             width: 20px;
    //             margin-right: 5px;
    //         }
    //     }
    // }

    &.invalid {
        :global(.DraftEditor-root) {
            border: 2px solid $red;
        }
    }

    // &.valid {
    //     :global(.DraftEditor-root) {
    //         border: 2px solid $green;
    //     }

    //     svg {
    //         color: $green;
    //     }
    // }

    // .emojiButtonWrapper {
    //     position: absolute;
    //     top: 5px;
    //     right: 0;
    //     z-index: 5;

    //     .emojiSelectButton {
    //         border: 1px solid $grey2;
    //         border-radius: 5px;
    //         background-color: white;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         height: 32px;
    //         width: 32px;
    //         margin-right: 5px;
    //         cursor: pointer;
    //         font-size: 20px;
    //         padding-bottom: 6px;

    //         &:hover {
    //             background-color: $grey1;
    //         }

    //         &.selected {
    //             background-color: $grey1;
    //         }
    //     }

    //     .emojiSelectPopover {
    //         position: absolute;
    //         background-color: white;
    //         top: 42px;
    //         left: -270px;
    //         box-shadow: 0 1px 10px 0 rgba(10, 8, 72, 0.2);
    //         border-radius: 5px;

    //         ul {
    //             padding: 0;
    //         }
    //     }
    // }
}
