@import '@styles/Variables';

.wrapper {
    h1 {
        margin-bottom: 30px;
    }
    .action {
        margin-bottom: 10px;

        > svg {
            width: 30px;
            height: 30px;
            margin: 0 30px;
            color: $grey5;
        }

        .bead {
            outline: 1px solid rgba(black, 0.1);
            box-shadow: 0 0 10px 0 rgba(black, 0.1);
        }
    }
}
