@import '@styles/Variables';

.wrapper {
    background-color: $black2;
    width: 100%;
    height: $navbarHeight;
    position: fixed;
    top: 0;
    z-index: $navbarZIndex;

    .mobileLeft {
        display: flex;
        @include for-tablet-landscape-up {
            display: none;
        }

        &.entering > .hamburgerMenuBackground {
            background-color: rgba(black, 0.5);
        }
        &.entering > .hamburgerMenu {
            left: 0;
        }
        &.exiting > .hamburgerMenuBackground {
            background-color: rgba(black, 0);
        }
        &.exiting > .hamburgerMenu {
            left: -302px;
        }

        > button {
            all: unset;
            cursor: pointer;
            width: $navbarHeight;
            height: $navbarHeight;
            display: flex;
            justify-content: center;
            align-items: center;

            > svg {
                width: 40px;
                height: 40px;
                color: $grey5;
                transition: color 0.5s;
            }

            &:hover > svg {
                color: white;
            }
        }

        .hamburgerMenuBackground {
            all: unset;
            position: fixed;
            top: $navbarHeight;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(black, 0);
            transition: background-color 0.5s;
        }

        .hamburgerMenu {
            position: fixed;
            top: $navbarHeight;
            left: -302px;
            width: 300px;
            height: calc(100% - 60px);
            padding: 20px;
            background-color: white;
            box-shadow: 0 1px 10px 0 rgba(black, 0.1);
            transition: left 0.5s;

            .homeButton {
                all: unset;
                cursor: pointer;
                width: 40px;
                height: 40px;
                margin-right: 5px;

                > svg {
                    width: 40px;
                    height: 40px;
                }
            }

            .hamburgerMenuHeader {
                margin-bottom: 20px;
                word-break: break-word;
                h1 {
                    margin: 0;
                }
            }

            .hamburgerMenuTabs {
                margin-bottom: 20px;
                flex-shrink: 0;

                > a {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    color: $grey7;
                    transition: color 0.3s;

                    &:hover {
                        color: $blue5;
                    }

                    > svg {
                        width: 30px;
                        height: 30px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .desktopLeft {
        display: none;
        @include for-tablet-landscape-up {
            display: flex;
        }

        .homeButton {
            all: unset;
            cursor: pointer;
            width: 40px;
            height: 40px;
            margin: 0 20px 0 10px;
            transition: transform 0.5s;

            > svg {
                width: 40px;
                height: 40px;
            }
        }

        .exploreButton {
            position: relative;
            display: flex;
            align-items: center;

            > a {
                color: $grey5;
                font-size: 18px;
                margin-right: 10px;
                transition: color 0.5s;
            }

            > svg {
                width: 18px;
                height: 18px;
                color: $grey5;
                transition: color 0.5s;
            }

            &:hover {
                > a {
                    color: white;
                }
                > svg {
                    color: white;
                }
            }

            .exploreDropDown {
                position: absolute;
                left: -10px;
                top: 25px;
                background-color: $black2;
                padding: 15px 15px 5px 15px;
                border-radius: 5px;

                > a {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    > svg {
                        width: 22px;
                        height: 22px;
                        color: $grey5;
                        margin-right: 10px;
                        transition: color 0.5s;
                    }

                    > p {
                        color: $grey5;
                        transition: color 0.5s;
                    }

                    &:hover {
                        > svg {
                            color: white;
                        }
                        > p {
                            color: white;
                        }
                    }
                }
            }
        }
    }

    .mobileCenter {
        display: flex;
        @include for-tablet-landscape-up {
            display: none;
        }
        z-index: 5;

        &.entering > .mobileSearchBackground {
            background-color: rgba(black, 0.5);
        }
        &.entering > .mobileSearchDropDown {
            transition-delay: 0s;
            height: 60px;
            > form {
                transition-delay: 0.3s;
                opacity: 1;
            }
        }
        &.exiting > .mobileSearchBackground {
            background-color: rgba(black, 0);
        }
        &.exiting > .mobileSearchDropDown {
            transition-delay: 0.3s;
            height: 0px;
            > form {
                transition-delay: 0s;
                opacity: 0;
            }
        }

        > button {
            all: unset;
            cursor: pointer;
            width: $navbarHeight;
            height: $navbarHeight;
            display: flex;
            justify-content: center;
            align-items: center;

            > svg {
                width: 34px;
                height: 34px;
                color: $grey5;
                transition: color 0.5s;
            }

            &:hover > svg {
                color: white;
            }
        }

        .mobileSearchBackground {
            all: unset;
            position: fixed;
            top: $navbarHeight;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(black, 0);
            transition: background-color 0.5s;
        }

        .mobileSearchDropDown {
            position: fixed;
            top: $navbarHeight;
            height: 0px;
            width: 100%;
            left: 0;
            background-color: $black2;
            transition: height 0.5s;

            > form {
                opacity: 0;
                transition: opacity 0.5s;
                transition-delay: 0.3s;
            }
        }
    }

    .desktopCenter {
        position: absolute;
        top: 10px;
        left: calc(50% - 250px);
        display: none;
        @include for-desktop-small-up {
            left: calc(50% - 350px);
        }
        @include for-tablet-landscape-up {
            display: flex;
        }
    }

    .mobileRight {
        display: flex;
        @include for-tablet-landscape-up {
            display: none;
        }

        &.entering > .accountMenuBackground {
            background-color: rgba(black, 0.5);
        }
        &.entering > .accountMenu {
            right: 0;
        }
        &.exiting > .accountMenuBackground {
            background-color: rgba(black, 0);
        }
        &.exiting > .accountMenu {
            right: -302px;
        }

        > button {
            all: unset;
            cursor: pointer;
            width: $navbarHeight;
            height: $navbarHeight;
            display: flex;
            justify-content: center;
            align-items: center;

            > svg {
                width: 34px;
                height: 34px;
                color: $grey5;
                transition: color 0.5s;
            }

            &:hover > svg {
                color: white;
            }
        }

        .accountMenuBackground {
            all: unset;
            position: fixed;
            top: $navbarHeight;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(black, 0);
            transition: background-color 0.5s;
        }

        .accountMenu {
            position: fixed;
            top: $navbarHeight;
            right: -302px;
            width: 300px;
            height: calc(100% - 60px);
            padding: 20px;
            background-color: white;
            box-shadow: 0 1px 10px 0 rgba(black, 0.1);
            transition: right 0.5s;

            .accountMenuHeader {
                margin-bottom: 20px;
                word-break: break-word;
                h1 {
                    margin: 0;
                }
            }

            .accountMenuTabs {
                margin-bottom: 20px;

                > a {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    color: $grey7;
                    transition: color 0.3s;

                    &:hover {
                        color: $blue5;
                    }

                    > svg {
                        width: 30px;
                        height: 30px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .desktopRight {
        display: none;
        @include for-tablet-landscape-up {
            display: flex;
        }
        > a {
            position: relative;
            display: flex;
            align-items: center;
            color: $grey5;
            margin-right: 30px;

            > svg {
                width: 24px;
                height: 24px;
            }
        }

        .profileButton {
            display: flex;
            align-items: center;
            padding: 0;
            border: none;
            cursor: pointer;
            padding-right: 10px;

            > p {
                color: $grey5;
                font-size: 18px;
                margin-right: 20px;
                transition: color 0.5s;
            }

            &:hover {
                > p {
                    color: white;
                }
            }

            .profileDropDown {
                position: absolute;
                right: 0;
                top: 50px;
                background-color: $black2;
                padding: 10px 20px 15px 15px;
                border-radius: 5px;

                > a {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    > svg {
                        width: 22px;
                        height: 22px;
                        color: $grey5;
                        margin-right: 10px;
                        transition: color 0.5s;
                    }

                    > p {
                        color: $grey5;
                        transition: color 0.5s;
                    }

                    &:hover {
                        > svg {
                            color: white;
                        }
                        > p {
                            color: white;
                        }
                    }

                    .unseenItems {
                        width: 25px;
                        height: 25px;
                        background-color: #ff4848;
                        border: 3px solid $black2;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 5px;
                        > p {
                            font-size: 12px;
                            line-height: 12px;
                            font-weight: 800;
                            color: white;
                        }
                    }
                }
            }

            .totalUnseenItems {
                width: 25px;
                height: 25px;
                background-color: #ff4848;
                border: 3px solid $black2;
                border-radius: 50%;
                position: absolute;
                right: 5px;
                top: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                > p {
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 800;
                    color: white;
                }
            }
        }
    }
}
