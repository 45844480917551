@import '@styles/Variables';

.markdown {
    position: relative;
    // max-height: 200px;
    // margin-bottom: 10px;
    // overflow: hidden;
    color: black;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    text-decoration: none;
    overflow-wrap: break-word;
    word-break: break-word;
    // transition-property: color;
    // transition-duration: 2s;
    &.showFullText {
        max-height: none;
    }
    //overflow: hidden;
    ul {
        list-style-type: disc;
    }
    ul,
    ol {
        padding-left: 30px;
        margin-bottom: 10px;

        > li > input {
            margin-left: -20px;
            margin-right: 10px;
        }
    }
    h1 {
        line-height: 30px;
        margin-bottom: 15px;
    }
    h2 {
        line-height: 25px;
        margin-bottom: 15px;
    }
    h3 {
        line-height: 20px;
        margin-bottom: 15px;
    }
    img {
        max-width: 100%;
        max-height: 500px;
        display: block;
        margin: 20px auto;
    }
    a {
        color: #82afff;
        &:hover {
            cursor: pointer;
        }
    }
    p {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    hr {
        margin: 20px 0;
        height: 2px;
        border: none;
        background-color: #ddd;
    }
    pre {
        padding: 10px;
        background-color: $grey2;
        border-radius: 5px;
        margin: 10px 0;
        code {
            padding: 0;
            background-color: none;
            border-radius: none;
            line-height: 20px;
        }
    }
    code {
        font-family: monospace;
        padding: 5px;
        margin: 0 5px;
        background-color: $grey2;
        border-radius: 5px;
        line-height: 30px;
    }
    blockquote {
        margin: 0 0 20px 0;
        padding: 0 10px;
        color: #6a737d;
        border-left: 3px solid #cacaca;
    }
    table {
        border: 1px solid #dee2e6;
        border-spacing: 0;
        border-collapse: collapse;
        > thead > tr {
            border-top: 1px solid #dee2e6;
            background-color: #f6f8fa;
            > th {
                padding: 10px;
                border: 1px solid #dee2e6;
            }
        }
        > tbody > tr {
            border-top: 1px solid #dee2e6;
            > td {
                padding: 10px;
                border: 1px solid #dee2e6;
            }
        }
    }
}
