@import '@styles/Variables';

.wrapper {
    margin-bottom: 10px;
    width: 100%;
    @include for-post-width-up {
        width: auto;
    }

    .content {
        width: 100%;
        @include for-post-width-up {
            width: auto;
        }

        .sideBar {
            position: sticky !important;
            top: 116px;
            left: 0;
            max-height: calc(100vh - 250px);
            min-width: 200px;
            align-items: start;
            flex-shrink: 0;

            .header {
                color: $grey4;
                margin-bottom: 10px;

                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }

            .streamButton {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                > div {
                    width: 32px;
                    height: 32px;
                    background-image: linear-gradient(141deg, #9fb8ad 0%, #1fc8db 51%, #2cb5e8 75%);
                    border-radius: 50%;
                    color: white;
                    margin-right: 5px;

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }

                &.large {
                    font-size: 18px;

                    > div {
                        width: 40px;
                        height: 40px;

                        svg {
                            width: 26px;
                            height: 26px;
                        }
                    }
                }
            }

            .editButton {
                all: unset;
                cursor: pointer;
                width: 16px;
                height: 16px;
                margin-left: 10px;
                color: $grey4;

                svg {
                    width: inherit;
                    height: inherit;
                }
            }

            .divider {
                width: 150px;
                height: 2px;
                margin-bottom: 10px;
                background-color: $grey2;
            }

            .greyText {
                font-size: 14px;
                color: $grey4;
            }
        }

        .postListView {
            width: 100%;
            @include for-desktop-small-up {
                width: auto;
                min-width: 800px;
            }

            .streamNotFound {
                width: 100%;
                height: 80px;
                background-color: white;
                border-radius: 10px;
            }
        }

        .postMapView {
            width: calc(100vw - 40px);
        }
    }
}
