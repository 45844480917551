@import '@styles/Variables';

.wrapper {
    padding: 10px 10px 5px 10px;
    background-color: $grey1;
    margin: 0 -15px 0 -15px;

    @include for-tablet-portrait-up {
        border-radius: 10px;
        margin: 0;
        border: 1px solid $grey2;
    }

    .images {
        padding-bottom: 5px;

        .image {
            position: relative;

            &:not(:last-child) {
                margin-right: 10px;
            }

            > button {
                all: unset;
                cursor: pointer;

                > img {
                    border-radius: 5px;
                    object-fit: cover;
                    border: 1px solid $grey2;
                    width: 100%;
                    height: 100%;
                }
            }

            &.large {
                > button {
                    max-width: 100%;
                    max-height: 450px;
                }
            }

            &.medium {
                > button {
                    width: 300px;
                    height: 300px;
                }
            }

            &.small {
                > button {
                    width: 222px;
                    height: 222px;
                }
            }

            > p {
                width: 100%;
                text-align: center;
                overflow-wrap: break-word;
                font-size: 16px;
                line-height: 16px;
                margin: 10px 0 5px 0;
            }
        }
    }

    .selectedImage {
        > button {
            all: unset;
            cursor: pointer;
            > svg {
                color: $grey3;
                width: 25px;
                height: 25px;
                &:hover {
                    color: $grey4;
                }
                transition: color 0.5s;
            }
        }
        > div {
            margin: 0 10px;
            > img {
                max-width: calc(100vw - 200px);
                max-height: calc(100vh - 200px);
                margin-bottom: 20px;
            }
        }
    }
}
