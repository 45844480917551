@import '@styles/Variables';

.wrapper {
    position: relative;
    margin-bottom: 10px;
    background-color: $grey1;
    border: 1px solid $grey2;
    border-radius: 10px;
    padding: 20px 30px;

    .card {
        $baseWidth: 250;
        $baseHeight: 350;
        $baseFontSize: 16;
        $baseLineHeight: 22;
        $baseBorderRadius: 15;
        $basePadding: 20;

        width: $baseWidth + px;
        height: $baseHeight + px;
        max-width: 100vw;
        font-size: $baseFontSize + px;
        line-height: $baseLineHeight + px;
        border-radius: $baseBorderRadius + px;
        box-shadow: 0 0 30px 0 rgba(10, 8, 72, 0.2);
        background-color: white;
        transition: all ease-in-out 0.5s;

        // code blocks
        pre {
            > div {
                border-radius: ($baseBorderRadius / 2) + px;
                padding: ($basePadding / 2) + px;
                line-height: $baseLineHeight + px;
                transition-property: line-height, border-radius, padding;
                transition-duration: 0.5s;
                transition-timing-function: ease-in-out;
            }
        }

        .cardContent {
            position: absolute;
            pointer-events: none;
            opacity: 0;
            width: 100%;
            height: 100%;
            border-radius: inherit;
            padding: $basePadding + px;
            transition: padding ease-in-out 0.5s;

            > button {
                all: unset;
                cursor: pointer;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            > img {
                position: absolute;
                left: 0;
                top: 0;
                border-radius: inherit;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &.visible {
                position: relative;
                opacity: 1;
                pointer-events: all;
            }
        }

        &.focused {
            $focusScale: 1.4;
            width: $focusScale * $baseWidth + px;
            height: $focusScale * $baseHeight + px;
            font-size: $focusScale * $baseFontSize + px;
            line-height: $focusScale * $baseLineHeight + px;
            border-radius: $focusScale * $baseBorderRadius + px;

            .cardContent {
                padding: $basePadding * $focusScale + px;
            }

            // code blocks
            pre {
                > div {
                    border-radius: $focusScale * ($baseBorderRadius / 2) + px;
                    padding: $focusScale * ($basePadding / 2) + px;
                    line-height: $focusScale * $baseLineHeight + px;
                }
            }
        }

        &.rotating {
            transform: rotateY(90deg);
        }
    }

    .largeScreenIcons {
        display: none;
        position: absolute;
        height: 100%;
        right: 0;
        top: 0;
        padding: 20px;
        align-items: end;

        @include for-tablet-portrait-up {
            display: flex;
        }
    }

    .smallScreenHeader,
    .smallScreenIcons {
        display: flex;

        @include for-tablet-portrait-up {
            display: none;
        }
    }

    .largeScreenIcons,
    .smallScreenHeader,
    .smallScreenIcons {
        button {
            all: unset;
            cursor: pointer;
            color: $grey4;
            transition: color 0.5s;

            &:hover {
                color: $grey6;
            }

            &.blue {
                color: $blue1;
                &:hover {
                    color: $blue1;
                }
            }

            svg {
                width: 25px;
                height: 25px;
            }

            p {
                margin-left: 7px;
                font-size: 17px;
            }
        }
    }

    .smallScreenIcons {
        button {
            margin: 0 10px;
        }
    }
}
