@import '@styles/Variables';

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    color: $grey4;

    &:not(:last-child) {
        margin-right: 12px;
    }

    &:hover {
        color: $grey6;
        cursor: pointer;
    }

    > button {
        all: unset;
        transition: color 0.5s;
    }

    p {
        font-size: 17px;
        margin-left: 6px;
        &:last-child {
            margin-right: 0;
        }
    }

    .icon {
        > svg {
            width: inherit;
            height: inherit;
        }

        &.blue {
            color: $blue1;
        }
    }
}
