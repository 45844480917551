@import '@styles/Variables';

.wrapper {
    width: 100%;
    height: calc(100vh - 120px);
    margin-top: -15px;

    .icon {
        width: 26px;
        height: 26px;
        color: $grey4;
        margin-right: 10px;
    }

    .sidebar {
        width: 250px;
        height: 100%;
        padding: 10px;
        border-right: 1px solid $grey2;
        flex-shrink: 0;

        @include for-desktop-small-up {
            width: 350px;
        }

        .chat {
            margin-top: 10px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            padding: 5px;
            justify-content: space-between;

            h1 {
                font-size: 16px;
                font-weight: 800;
                margin: 0;
            }

            p {
                font-size: 14px;
                color: $grey6;
            }

            .unseenMessages {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: $blue2;
                flex-shrink: 0;
                margin-left: 10px;
                color: white;
                font-size: 12px;
                line-height: 12px;
            }

            &.selected {
                background-color: $blue8;
            }
        }
    }

    .messages {
        position: relative;
        height: 100%;
        background-color: white;

        width: calc(100% - 250px);
        @include for-desktop-small-up {
            width: calc(100% - 350px);
        }

        .header {
            width: 100%;
            height: 60px;
            flex-shrink: 0;
            background-color: white;
            border-bottom: 1px solid $grey2;
            padding: 0 10px;

            h1 {
                font-size: 16px;
                font-weight: 800;
                margin: 0;
            }
        }

        .scrollWrapper {
            overflow-y: scroll;
            padding: 0 10px 5px 10px;
            flex-direction: column-reverse;
            height: 100%;
        }

        .input {
            width: 100%;
            padding: 10px;
            margin-bottom: 10px;
            background-color: white;

            .replyParent {
                position: relative;
                background-color: $blue8;
                border: 1px solid $blue5;
                border-radius: 10px;
                padding: 5px 10px;
                margin-bottom: 10px;

                .closeButton {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    width: 20px;
                    height: 20px;
                    background-color: white;
                    border: 1px solid $grey4;
                    border-radius: 50%;
                }

                .replyParentHeader {
                    > svg {
                        width: 16px;
                        height: 16px;
                        color: $grey5;
                        margin-right: 8px;
                        transform: rotate(180deg);
                    }
                    > h1 {
                        font-size: 16px;
                        font-weight: 800;
                        margin: 0;
                    }
                }

                .replyParentUrl {
                    margin: 5px 0;
                    padding: 5px;
                    background-color: white;
                    border-radius: 5px;
                    border: 1px solid $grey2;
                    width: 100%;
                    max-width: 600px;

                    > img {
                        height: 50px;
                        width: 80px;
                        border-radius: 5px;
                        object-fit: cover;
                        margin-right: 5px;
                    }

                    h1 {
                        font-size: 16px;
                        font-weight: 800;
                        margin: 0;
                    }
                }

                .replyParentImages {
                    margin: 5px 0;

                    > img {
                        width: 80px;
                        height: 80px;
                        object-fit: cover;
                        margin-right: 10px;
                        border-radius: 5px;
                    }
                }
            }

            // user typing text
            .peopleTyping {
                margin: -6px 0 5px 0;
                align-items: flex-end;
                > p {
                    color: $grey4;
                    margin-right: 2px;
                }
            }
        }
    }

    // upload
    .image {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: $grey2;
        margin-bottom: 20px;
        position: relative;

        > img {
            width: inherit;
            height: inherit;
            border-radius: inherit;
            object-fit: cover;
            position: absolute;
            background-color: white;
            box-shadow: 0 0 10px 0 rgba(10, 8, 72, 0.1);
        }

        > svg {
            color: $grey5;
            width: 60px;
            height: 60px;
        }

        > label {
            position: absolute;
            width: 120px;
            height: 120px;
            cursor: pointer;
            border-radius: 50%;
        }
    }
}
