@import '@styles/Variables';

.plainButton {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: $grey3;
    transition: color 0.5s;

    &:hover {
        color: $grey4;
    }
}
