@import '@styles/Variables';

.button {
    border-radius: 20px;
    padding: 0px 14px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-property: background-color, filter, color;
    transition-duration: 0.3s;
    flex-shrink: 0;

    &:hover {

        // &:focus
        &:not(.disabled) {
            cursor: pointer;
            filter: brightness(110%);

            &.grey {
                filter: brightness(96%);
            }
        }
    }

    // colours
    &.blue {
        background-color: $blue5; //#51a6dc; //#3a88f0;
        color: white;
    }

    &.aqua {
        background-color: $aqua; //#00b178; //#17b35d;
        color: white;
    }

    &.red {
        background-color: $red2; //#00b178; //#17b35d;
        color: white;
    }

    &.grey {
        background-color: $grey2; //#00b178; //#17b35d;
        color: $black2;

        >svg>path {
            fill: $grey6;
        }
    }

    &.purple {
        background-color: $purple1;
        color: white;
    }

    &.game-black {
        background-color: black;
        color: white;
    }

    &.game-white {
        border: 2px solid black;
        background-color: white;
        color: black;
    }

    &.aqua-gradient {
        background-image: linear-gradient(141deg, #9fb8ad 0%, #1fc8db 51%, #2cb5e8 75%);
        color: white;
    }

    // sizes
    &.small {
        font-size: 10px;
        height: 20px;
        padding: 0 10px;

        >svg {
            width: 10px;
            height: 10px;

            &:not(:last-child) {
                margin-right: 3px;
                margin-left: -1px;
            }
        }
    }

    &.medium {
        font-size: 14px;
        height: 30px;
        padding: 0 15px;
    }

    &.medium-large {
        font-size: 14px;
        height: 35px;
        padding: 0 15px;
    }

    &.large {
        font-size: 14px;
        line-height: 14px;
        height: 36px;
    }

    // disabled
    &.disabled {
        >p {
            opacity: 0.5;
        }
    }

    // icon
    >svg {
        width: 20px;
        height: 20px;
        opacity: 0.7;

        &:not(:last-child) {
            margin-right: 7px;
            margin-left: -5px;
            // opacity: 0.7;
        }
    }

    // loading wheel
    >div {
        margin: 0 -10px 0 10px;
    }
}
