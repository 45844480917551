@import '@styles/Variables';

.wrapper {
    width: 100px;
    height: 100px;
    outline: 1px solid $grey3; //rgba(black, 0.2);
    border-radius: 10px;
    background-color: white; // $grey1;
    margin-right: 10px;
    cursor: move;
    position: relative;
    transition: all 0.5s;

    &.deleted {
        p {
            font-size: 12px;
            text-align: center;
        }
    }

    .watermark {
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
        background-color: white;
        border: 2px solid white;
        border-radius: inherit;
        opacity: 0.1;
        // z-index: 1;
        pointer-events: none;
    }

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: $red;
        border-radius: 10px;
        z-index: 5;
    }

    .button {
        all: unset;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    .item {
        padding: 4px;
        width: 100%;
        height: 100%;

        .footer,
        .header {
            height: 18px;

            > svg {
                width: 18px;
                height: 18px;
                color: $grey5;
            }
        }

        .center {
            height: 100%;
            width: 100%;
            overflow: hidden;

            p {
                font-size: 12px;
            }

            div {
                width: 100%;
                height: 48px;
                border-radius: 4px;
                background-size: cover;
                background-position: center;
            }

            svg {
                width: 50px;
                height: 50px;
                color: $grey5;
            }

            img {
                width: 40px;
                height: 56px;
                border-radius: 3px;
                box-shadow: 0 0 6px 0 rgba(black, 0.18);
                overflow: hidden;
                object-fit: cover;
            }
        }
    }

    .agent {
        height: 100%;

        .coverImage {
            width: 100%;
            height: 74px;
            background-size: cover;
            background-position: center;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        .flagImage {
            top: 7px;
            position: absolute;
        }

        .footer {
            height: 24px;
            font-size: 12px;
            color: $grey7;
            max-width: 100%;
            overflow: hidden;

            > svg {
                width: 15px;
                height: 15px;
                color: $grey5;
                margin-right: 5px;
            }
        }
    }

    &.dragging {
        opacity: 0.3;
    }

    &.noTransition {
        transition: none;
    }

    &.moveRight {
        transform: translateX(110px);
    }

    &.removing {
        opacity: 0;
        width: 0;
        margin-right: 0;
    }

    &.fade {
        opacity: 0;
    }

    &.highlighted {
        .overlay {
            animation: fade-highlight 1s infinite;
        }
    }

    @keyframes fade-highlight {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            opacity: 0;
        }
    }

    .modal {
        position: fixed;

        .bead {
            outline: 1px solid rgba(black, 0.1);
            box-shadow: 0 0 10px 0 rgba(black, 0.1);
        }
    }
}
