@import '@styles/Variables';

.wrapper {
    margin-bottom: 10px;
    width: 100%;
    @include for-post-width-up {
        width: auto;
    }

    .content {
        width: 100%;
        @include for-post-width-up {
            width: auto;
        }

        .postListView {
            width: 100%;
            @include for-desktop-small-up {
                width: auto;
                min-width: 800px;
            }
        }

        .postMapView {
            width: calc(100vw - 40px);
        }
    }
}
