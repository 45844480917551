@import '@styles/App.scss';

.wrapper {
    height: 100%;
    width: 100%;

    .header {
        svg {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            color: $grey4;
        }
        p {
            color: $grey5;
        }
    }

    .stats {
        > svg {
            width: 15px;
            height: 15px;
            color: $grey4;
            margin-right: 3px;
        }
        > p {
            color: $grey5;
            margin-right: 10px;
        }
    }

    .loadMore {
        all: unset;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 24px;
        width: 100%;
        font-size: 14px;
        color: $blue1;
    }
}
