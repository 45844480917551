@import '@styles/App.scss';

.wrapper {
    min-height: 800px;
    width: calc(100% - 30px);
    max-width: 1600px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 135px;

    .header {
        width: 100%;

        .month {
            width: 280px;
            margin-bottom: 10px;

            > h1 {
                margin: 0 10px;
                line-height: 26px;
            }

            > button {
                all: unset;
                cursor: pointer;
                width: 20px;
                height: 20px;

                > svg {
                    width: 20px;
                    height: 20px;
                    color: $grey3;
                }
            }
        }
        .days {
            display: none;
            @include for-tablet-landscape-up {
                display: flex;
            }

            > p {
                width: calc(100% / 7);
                text-align: center;
            }
        }
    }

    .days {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include for-tablet-landscape-up {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .padding,
        .day {
            width: 100%;
            height: 150px;
            margin: 5px;
            border-radius: 5px;

            @include for-tablet-landscape-up {
                width: calc(100% / 7 - 10px);
            }
        }

        .padding {
            background-color: $grey1;
            display: none;
            @include for-tablet-landscape-up {
                display: flex;
            }
        }

        .day {
            border: 1px solid $grey2;
            padding: 10px;

            &.highlighted {
                border: 2px solid $blue2;
            }

            .dayNumber {
                font-size: 14px;
                font-weight: 800;
                @include for-tablet-landscape-up {
                    font-weight: 400;
                }
            }

            .dayName {
                display: flex;
                @include for-tablet-landscape-up {
                    display: none;
                }
                font-size: 14px;
                margin-left: 8px;
            }

            .event {
                all: unset;
                cursor: pointer;
                display: flex;
                width: calc(100% - 10px);
                margin-bottom: 5px;
                padding: 5px;
                border-radius: 5px;
                word-break: break-word;

                &.event {
                    background-color: $blue8;
                }

                &.glass-bead-game {
                    background-color: $purple2;
                }

                > p {
                    font-size: 12px;
                    margin-right: 5px;

                    &:first-child {
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
}
