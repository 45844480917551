@import '@styles/Variables';

.wrapper {
    position: relative;
    width: 100%;

    .controls {
        width: 100%;
        max-width: 750px;
        margin-top: 5px;
        height: 70px;
        position: absolute;

        .loadMore {
            all: unset;
            cursor: pointer;
            color: $blue1;
            margin-left: 10px;
        }

        .gravitySlider {
            cursor: pointer;
            margin: 0 10px;
        }

        .gravityInput {
            font-size: 16px;
            border: none;
            outline: none;
            background-color: white;
            box-shadow: 0 1px 10px 0 rgba(10, 8, 72, 0.05);
            width: 50px;
            border-radius: 5px;
            padding: 5px;
            text-align: center;
        }
    }

    .noPosts {
        position: absolute;
        top: 30%;
    }
}
