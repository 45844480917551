.wrapper {
    display: flex;
    flex-direction: column;
    // overflow-y: scroll;
    &.centerX {
        align-items: center;
    }
    &.centerY {
        justify-content: center;
    }
    &.spaceBetween {
        justify-content: space-between;
    }
    &.scroll {
        overflow: auto;
    }
}
