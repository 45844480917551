@import '@styles/Variables';

.wrapper {
    border-radius: 50%;
    display: flex;
    background-color: $white;
    flex-shrink: 0;

    .flagImage {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        flex-shrink: 0;
    }
}
